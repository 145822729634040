import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Logo from '../assets/logo1.png';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import Sidebar from "../Layouts/sidebar";
import Sidebarheader from "../Components/sidebarheader";
import { Link, useLocation } from "react-router-dom";
import dashimg from "../assets/bitimg.png";
import betimg from "../assets/bet.png";
import Lottery from "../assets/lottery.png";
import triimg from "../assets/tri.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BsStarFill } from 'react-icons/bs';
import Gameheader from '../Layouts/gamesheader';
import { getAllGamesInfoHooks } from "../hooks/UseUser";
import { BACKURL, FRONTENDURL } from "../config/env";

export default function Games() {

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();
      }, []);
      let location = useLocation()
      const [gameCard, setGameCard] = useState([])

    const getData = async () => {
        try {
            let res = await getAllGamesInfoHooks();
            console.log("getallgamesInfoHooks", res, res?.data?.data);
            if (res?.status == 200) {
                if (res?.data?.success == false) {

                } else {
                    if (res?.data?.data) {
                        setGameCard(res?.data?.data)
                    }
                }

            }
        } catch (e) {
            console.log("getData_err", e)
        }
    }


    const [listed, setlisted] = useState([
        { "id": 1, }, { "id": 2, }, { "id": 3, }, { "id": 4, },
    ]);
    const [gamecard, setgamecard] = useState([
        { "id": 1,"images": require("../assets/cardimg.png"),"title":"Coin Flip" }, 
        { "id": 2,"images": require("../assets/range.svg.png"),"title":"Range" }, 
        { "id": 3,"images": require("../assets/moon.svg.png"),"title":"Moon" }, 
        { "id": 4,"images": require("../assets/wheel.svg.png"),"title":"Wheel" }, 
        { "id": 5,"images": require("../assets/plinko.svg.png"),"title":"Plinko" }, 
        { "id": 6,"images": require("../assets/Group (2).png"),"title":"Dice" }, 
        { "id": 7,"images": require("../assets/limbo.svg.png"),"title":"Limbo" }, 
    ]);
  

    const options12 = {
        margin: 10,
        responsiveClass: true,
        nav: false,
        dots: true,
        items: 3,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            }
        }
    }

    return (
        <>
            <div className="sidebarlist">
                <Gameheader />
                <Sidebar />

                <div className="content pt-3">
                    <div className="container">       
                        <Sidebarheader/>
                        <div className="row pt-5">
                            <div className="col-xl-4 col-lg-6 col-md-6 mt-3">
                                <div className="dashboard">
                                  <img className="tri" src={triimg} alt="contest" />
                                    <div className="row align-items-center">
                                        <div className="col-lg-3 col-sm-4 col-md-3 mt-3"><img className="img-fluid" src={dashimg} alt="contest" /></div>
                                        <div className="col-lg-9 col-sm-8 col-md-9 mt-3">
                                            <h6 className="fw-800 mb-1">WLS</h6>
                                            <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">Size</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">2,14,300 $</p></div> </div>
                                            <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">APR</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">123.87 %</p></div> </div>
                                            <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">Bribe</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">0.0125 x</p></div> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 mt-3">
                                <div className="dashboard">
                                <img className="tri" src={triimg} alt="contest" />
                                    <div className="row align-items-center">
                                        <div className="col-lg-3 col-sm-4 col-md-3 mt-3"><img className="img-fluid" src={dashimg} alt="contest" /></div>
                                        <div className="col-lg-9 col-sm-8 col-md-9 mt-3">
                                            <h6 className="fw-800 mb-1">Real Yield</h6>
                                            <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">Staked</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">2,14,300 $</p></div> </div>
                                            <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">APR</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">123.87 %</p></div> </div>
                                            <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">Multiplier</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">0.0125 x</p></div> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 mt-3">
                                <div className="dashboard">
                                <img className="tri" src={triimg} alt="contest" />
                                    <div className="row align-items-center">
                                        <div className="col-lg-3 col-sm-4 col-md-3 mt-3"><img className="img-fluid" src={Lottery} alt="contest" /></div>
                                        <div className="col-lg-9 col-sm-8 col-md-9 mt-3">
                                            <h6 className="fw-800 mb-1">Lottery</h6>
                                            <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">Jackpot</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">2,14,300 $</p></div> </div>
                                            <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">Ticket</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">123.87 %</p></div> </div>
                                            <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">Next Draw</p></div> <div className="col-md-7 col-7 text-end"><button type="button" class="btn w-100 btn-success earnbtn notactive mt-1">Not Active Yet</button></div> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="owlcarousel pt-4">
                            <OwlCarousel {...options12} className='owl-theme'>
                                {listed.map((e, i) =>
                                    <div class='item'>
                                        <div className="row align-items-center">
                                            <div className="col-md-5 mt-4">
                                                <h2 className="fw-900 upto" style={{ color: "#5b606a" }}>UP TO $25,000</h2>
                                                <h1 className="fw-900 weekly">WEEKLY CONTEST </h1>
                                            </div>
                                            <div className="col-md-4 mt-4">
                                                <h6 className="fw-800 gen" style={{ color: "#95989d" }}>GENERATE VOLUME </h6>
                                                <h6 className="fw-800 us">AND EARN USDC </h6>
                                                <button type="button" class="btn btn-outline-secondary mt-2">Details</button>
                                            </div>
                                            <div className="col-md-3 mt-4">
                                              <img className="img-fluid liness" src={betimg} alt="contest" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </OwlCarousel>
                        </div>

                        <div className="cardss pt-3 pb-4">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="fw-600 mb-0"><BsStarFill /> Top</p>
                                {/* <button type="button" class="btn btn-light fw-600">View All</button> */}
                            </div>
                            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                                {console.log("gameCard", gameCard)}
                                {gameCard && gameCard?.length > 0  && gameCard?.map((e, i) =>
                                
                                   { 
                                   {console.log("eeeeeeeeee", e, e?.GameName)}
                                  return (<div class="col mt-3">
                                        <Link  onClick={() =>  window.location.href = `${FRONTENDURL}games/${e?.GameName}`} >
                                        <div class="card">
                                            <div className="card-img">
                                                <img className="img-fluid" src={`${BACKURL}/Allgames/${e?.Image}`} alt="contest" />
                                            </div>
                                            <div class="card-body">
                                                <h6 class="card-title mb-0">{e?.GameName}</h6>
                                            </div>
                                        </div>
                                        </Link>
                                    </div>)}
                                )}
                            </div>
                        </div>


                    </div>


                    <Footer />
                </div>

               
            </div>
        </>
    );
}


