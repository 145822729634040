import React,{useEffect, useState} from "react";
import { Button, Container, Row, Col } from 'react-bootstrap';
import Header from '../Layouts/Header';
import pig from '../assets/pig.png';
import groupcoin from '../assets/groupcoin.png';
import bottomcoin from '../assets/bottomcoin.png';
import { Link, useLocation, useNavigate } from "react-router-dom";
import facebook from '../assets/Frame (1).png';
import telegram from '../assets/Frame (2).png';
import discard from '../assets/Frame (3).png';
import mirror from '../assets/Frame (4).png';
import git from '../assets/Frame (5).png';
import AOS from "aos";
import "aos/dist/aos.css";
import { getuserdatabyrefferalIdhooks } from "../hooks/UseUser";

export default function Home() {
    const [userdata, setUserData] = useState({});
let location = useLocation();
const [showpage, setShowPage] = useState(true);
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

      let navigate = useNavigate()
      useEffect(() => {
          async function fetchData() {
              let refferalId = location?.pathname?.split("/")[1];
              console.log("licatiasdfasdf", location?.pathname?.split("/")[1]);
              let accountInfo = sessionStorage.getItem("accountInfo")
              console.log("check condition", (refferalId != null && refferalId != "" && refferalId != undefined)&& (accountInfo == null || accountInfo == undefined || accountInfo == "undefined"))
              if ((refferalId != null && refferalId != "" && refferalId != undefined) && (accountInfo == null || accountInfo == undefined || accountInfo == "undefined")) {
                
                  let res = await getuserdatabyrefferalIdhooks(refferalId);
                  console.log("resdata", res?.data?.record);
                  if (res?.data?.success == true) {
                    localStorage.setItem("refferalId", refferalId)
                      setUserData(res?.data?.record)
                      setShowPage(true)
                  } else {
                      setUserData({})
                      setShowPage(false)
                  }
              }
          }
          fetchData()
      }, [location]);
  
      useEffect(() => {
          let getRefferal = localStorage.getItem("refferalId");
          let accountInfo = sessionStorage.getItem("accountInfo")
          console.log("getRefferaldata", getRefferal)
          if((getRefferal != "" && getRefferal != null && getRefferal != undefined) && (accountInfo == null || accountInfo == undefined || accountInfo == "undefined")) {
              navigate(`/${getRefferal}`)
          } 
      }, [location])
    return (
        <>
            <div>
                {showpage ? <Header /> : ""}

                {showpage ?   <div className="home">
                    <div className="container allhome">
                        <div className="row pt-5">
                            <div className="col-md-6 pt-3" data-aos="fade-up" data-aos-duration="2000">
                                <h1 className="fw-800 mb-4">Win More Than<br></br> You Can Lose</h1>
                                <p>Decentralized on-chain casino. Be the house, or beat the house. Token
                                    rewards on each bet that owns you a piece of the casino forever, with
                                    daily revenue sharing.
                                    <br></br><br></br>
                                    Bet directly through your wallet, settled instantly into your address:
                                    no middleman, no KYC, no tampering, no bullsh*t.
                                </p>
                                <Link to="/games"><button type="button" class="btn btn-warning wallet play mt-4"> PLAY </button></Link>



                                {/* <div className="row contribution pt-3 align-items-center">
                                    <div className="col-6 col-sm-4 col-md-4 mt-3">
                                        <h6 className="fw-700">Betting Volume</h6>
                                        <h6 className="fw-600">$31,346,848</h6>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-4 mt-3">
                                        <h6 className="fw-700">Bankroll Size</h6>
                                        <h6 className="fw-600">$2,712,632</h6>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-4 mt-3">
                                        <h6 className="fw-700">Revenue Shared</h6>
                                        <h6 className="fw-600">$167,018</h6>
                                    </div>
                                </div> */}

                                <div className="pt-4 social">
                                    <Link className="mx-0" to="https://twitter.com/" target="_blank"><img className="img-fluid" src={facebook} alt="pig" /></Link>
                                    <Link to="https://telegram.org/" target="_blank"><img className="img-fluid" src={telegram} alt="pig" /></Link>
                                    <Link to="https://discord.com/" target="_blank"><img className="img-fluid" src={discard} alt="pig" /></Link>
                                    <Link to="/" target="_blank"><img className="img-fluid" src={mirror} alt="pig" /></Link>
                                    <Link to="https://github.com/" target="_blank"><img className="img-fluid" src={git} alt="pig" /></Link>
                                </div>

                            </div>
                            <div className="col-md-6 pt-3 leftside" data-aos="fade-down" data-aos-duration="2000">
                                <img className="img-fluid" src={pig} alt="pig" />
                                <img className="img-fluid allcoin" src={groupcoin} alt="pig" />
                            </div>
                        </div>
                        <img className="bottomcoin img-fluid" src={bottomcoin} alt="pig" />
                    </div>
                </div>  : <></>}

            </div>
        </>
    );
}