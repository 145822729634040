export const  style = {
      
    minWidth: '300px',
    minHeight: '55px',
    border: '1px solid #000',
    padding: '18px',
    color: '#000',
    fontWeight:"500",
    background: '#FFA60E',
    zIndex:"99999"
    
}

export const iconTheme = {
    primary: '#000',
    secondary: '#fff',
}

export const  position= {
    position: "top-center"
}


export const  zindex= {
    zIndex: "99999"
}

export const duration = {
    duration : 4000
}