
import Logo from '../assets/footerlogo.png';
import {  Link } from "react-router-dom";
import facebook from '../assets/Frame (1).png';
import telegram from '../assets/Frame (2).png';
import discard from '../assets/Frame (3).png';
import mirror from '../assets/Frame (4).png';
import git from '../assets/Frame (5).png';
import msgbtn from '../assets/msgbtn.png';

export default function Footer() {

    return (
        <>
            <div className="container footer">
                <hr></hr>
            <div className="row pt-3 pb-4">
              <div className="col-md-4 col-sm-12 px-3 mt-3">
              <Link to="/"><img src={Logo} className="img-fluid mb-3" alt="image" /></Link>
              <p className="foot-p fw-600">Lorem Ipsum is simply dummy text of the printing.</p>
              </div>
              <div className="col-md-2 col-sm-3 mt-3">
             {/* <ul>
                <h6 className="fw-700 mb-3">Support</h6>
                <li><Link to="/">Docs</Link></li>
                <li><Link to="/">Guides</Link></li>
             </ul> */}
              </div>
              <div className="col-md-2 col-sm-3 mt-3">
             <ul>
                <h6 className="fw-700 mb-3">Navigate</h6>
                <li><Link to="/earn">Earn</Link></li>
                <li><Link to="/referral">Referral</Link></li>
             </ul>
              </div>
              <div className="col-md-4 col-sm-6 mt-3">
              <h6 className="fw-700 mb-3">Social</h6>
              <div className="social">
                                    <Link to="https://twitter.com/" target="_blank" className="mx-0"><img className="img-fluid" src={facebook} alt="pig" /></Link>
                                    <Link to="https://telegram.org/" target="_blank"><img className="img-fluid" src={telegram} alt="pig" /></Link>
                                    <Link to="https://discord.com/" target="_blank"><img className="img-fluid" src={discard} alt="pig" /></Link>
                                    <Link to="/" target="_blank"><img className="img-fluid" src={mirror} alt="pig" /></Link>
                                    <Link to="https://github.com/" target="_blank"><img className="img-fluid" src={git} alt="pig" /></Link>
                                </div>
              </div>
            </div>
            {/* <div className="msgfun">
                <img src={msgbtn} className="img-fluid"/>
            </div> */}
            </div>

        </>
    );
}