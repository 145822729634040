import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Copimg from '../assets/copyimg.svg';
import Logo from '../assets/logo1.png';
import walletimg from '../assets/wallet.png';
import { NavLink, Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import Wallets from '../Components/Modals/Wallet';
import Select from 'react-select';
import tablecoin from '../assets/tablecoin.png';
import menuburger from '../assets/menuburger.png';
import goldcoin from '../assets/goldicon.png';
import Offcanvas from 'react-bootstrap/Offcanvas';
import contest from "../assets/cup.png";
import stakes from "../assets/stakess.png";
import rocket from "../assets/rocket.png";
import {getChainId} from "../hooks/useAccount"
import {CHAINS} from "../config/env"
import CopyToClipboard from "react-copy-to-clipboard";
import { copyUrl } from "../hooks/UseUser";
import { MdOutlineContentCopy } from "react-icons/md";

const options = [
    { value: 'wETH', label: <div><img src={tablecoin} height="18px" width="18px" /> wETH </div> },
    { value: 'wBTC', label: <div><img src={goldcoin} height="18px" width="18px" /> wBTC </div> },
    { value: 'USDC.e', label: <div><img src={tablecoin} height="18px" width="18px" /> USDC.e </div> },
];
const options1 = [
    { value: 'wETH', label: <div><img src={tablecoin} height="18px" width="18px" /> <span> 0 </span> wETH </div> },
    { value: 'wBTC', label: <div><img src={goldcoin} height="18px" width="18px" /> <span> 0 </span> wBTC </div> },
    { value: 'USDC.e', label: <div><img src={tablecoin} height="18px" width="18px" /> <span> 0 </span>  USDC.e </div> },
    // { value: 'USDC.e', label: <div><button>fdsfsdf</button> </div> },
];
export default function Header() {
    const [accountInfo, setAccountInfo] = useState("");

    console.log("accountInfo_accountInfo_V",accountInfo);
    const [currentChain,setcurrentChain]=useState(0)
    const [wallet, setShowWallet] = useState(false);

    const [walletmodal, setWalletmodal] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
console.log("walletmodaldata", walletmodal)
    const chainId = async ()=>{
        let chaindd = getChainId()
         console.log("chaindd_chainddchaindd", chaindd.at,CHAINS[currentChain]);
      //  let chainid =chaindd.CHAIN_ID
    //   console.log("chainid_chainid_chainidv",chainid);
        setcurrentChain(chaindd)

    }

    useEffect(() => {
        chainId()
       //  const index = localStorage.getItem("CHAIN");
       //  console.log("index___indexindex",index);
       //  setcurrentChain(index)
        
       const walletAddress = sessionStorage.accountInfo ? setAccountInfo(sessionStorage.getItem("accountInfo")) : ""
   }, [accountInfo]);

   const logOut = async () => {
    console.log("gameheader logout call")
    setAccountInfo("");
  
    localStorage.removeItem('accessToken');
    sessionStorage.removeItem('accountInfo');
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/"
}
    return (
        <>
            <div className="gameheader">
                {walletmodal && <Wallets onDismiss={() => setWalletmodal(false)} />}
                <div class="header_alert__8Mnqu">

                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                            {/* <h6 className="fw-600 smart mb-0">Volumes <span className="lgray smart"> $ 15,23,231</span></h6>
                            <h6 className="fw-600 smart mb-0 ms-3">Bets <span className="lgray smart"> $ 15,23,231</span></h6> */}
                        </div>

                        {(accountInfo == "" || accountInfo == null) ?
                            <div className="desktopview">
                               <button type="button" class="btn btn-success earnbtn mb-0 fw-600" onClick={() => setWalletmodal(true)}><img className="wimg" src={walletimg} alt="images" /> Connect Wallet</button>
                               {/* //veera */}
                                {/* <h6 className="mb-0 cw" onClick={() => setWalletmodal(true)}><img className="wimg" src={walletimg} alt="images" /> Connect wallet</h6>  */}
                            </div>
                            :
                            <div className="desktopview">
                                <div className="d-flex align-items-center">
                                    <div className="ms-3 walletdrop">
                                        <Dropdown onMouseLeave={() => setShowDropdown(false)}
                                            onMouseOver={() => setShowDropdown(true)}>
                                            <Dropdown.Toggle className="ptoken btn btn-success earnbtn" id="dropdown-basic">
             
                                                <img className="wimg" src={walletimg} alt="images" /> {`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu show={showDropdown} className="right_menu_custom">
                                                <div className="d-flex justify-content-between">
                                                    <p className="f-13 mb-0">{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</p>
                                                    <CopyToClipboard text={`${accountInfo}`} onCopy={() => { copyUrl('Copied WalletAddress', `${accountInfo}`) }}>
                                                        <MdOutlineContentCopy className="copysvg" />
                                                    </CopyToClipboard></div>
                                                {/* <div className="d-flex justify-content-between tokenborder">
                                                    <p className="f-13 mb-0">{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</p>
                                                    <svg width="17" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.556 1.686H5.444A2.444 2.444 0 0 0 3 4.13v6.112a2.444 2.444 0 0 0 2.444 2.444h3.112A2.444 2.444 0 0 0 11 10.242V4.13a2.444 2.444 0 0 0-2.444-2.444Z" stroke="#68758C" stroke-width="2"></path><path d="M14 4.686v8.556a2.444 2.444 0 0 1-2.444 2.444H6" stroke="#68758C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                                </div> */}
                                                <div className="mt-3">
                                                    <Link className="mt-2 mb-0" onClick={()=>logOut()} >Disconnect</Link>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>}

                        <div className="mobileview">
                            <img onClick={handleShow} src={menuburger} alt="menu" />
                        </div>

                    </div>





                    <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className="fw-700">PIGIBET</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            {localStorage.getItem("metamask") !== "true" ?
                                <div>
                                      {/* <button type="button" class="btn btn-success earnbtn mb-3" onClick={() => setWalletmodal(true)}><img className="wimg" src={walletimg} alt="images" /> Connect wallet</button> */}
                                    {/* <h6 className="mb-0 cw mb-3" onClick={() => setWalletmodal(true)}><img className="wimg" src={walletimg} alt="images" /> Connect wallet</h6> */}
                               
                                    {
                                    (accountInfo == "" || accountInfo == null) ?
                                        <button class="btn btn-success earnbtn mb-3" onClick={() => { setShowWallet(true) }}>Connect Wallet</button> :
                                        <button class="btn btn-success earnbtn mb-3" onClick={() => { logOut() }}>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`} <CopyToClipboard text={`${accountInfo}`} onCopy={() => { copyUrl('Copied WalletAddress', `${accountInfo}`) }}>
                                                        <MdOutlineContentCopy className="copysvg" />
                                                    </CopyToClipboard></button>
                                }
                               
                               
                                </div> :
                                <div>
                                <div className="ms-0 walletdrop mb-3">
                                    <Dropdown onMouseLeave={() => setShowDropdown(false)}
                                        onMouseOver={() => setShowDropdown(true)}>
                                        <Dropdown.Toggle className="ptoken btn btn-success earnbtn" id="dropdown-basic">
                                            <img className="wimg" src={walletimg} alt="images" /> 0x0a3C...2af9
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu show={showDropdown}>
                                            <div className="d-flex justify-content-between tokenborder">
                                                <p className="f-13 mb-0">0x0a3C...2af9</p>
                                                <svg width="17" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.556 1.686H5.444A2.444 2.444 0 0 0 3 4.13v6.112a2.444 2.444 0 0 0 2.444 2.444h3.112A2.444 2.444 0 0 0 11 10.242V4.13a2.444 2.444 0 0 0-2.444-2.444Z" stroke="#68758C" stroke-width="2"></path><path d="M14 4.686v8.556a2.444 2.444 0 0 1-2.444 2.444H6" stroke="#68758C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                            </div>
                                            <div className="mt-3">
                                                <Link to="/">My Profile</Link>
                                                <Link to="/">Statistics</Link>
                                                <div className="d-flex justify-content-between align-items-center mb-2">
                                                    <p className="f-15 fw-500 mb-0">Disable Tips</p>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="f-15 fw-500 mb-0">Music</p>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                    </div>
                                                </div>
                                                <Link className="mt-2 mb-0" onClick={()=>{logOut()}}  >Disconnect</Link>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            <div className="d-flex align-items-center">
                                <span className="fw-600">Currency</span>
                                <Select
                                    className="ms-2"
                                    // menuIsOpen={true}
                                    // defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                    defaultValue={{ value: 'wETH', label: <div><img src={tablecoin} height="18px" width="18px" /> wETH </div> }}
                                />
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                <span className="fw-600">Wallet</span>
                                <Select
                                    className="ms-4"
                                    // menuIsOpen={true}
                                    // defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options1}
                                    defaultValue={{  value: 'wETH', label: <div><img src={tablecoin} height="18px" width="18px" /> <span> 0 </span> wETH </div> }}
                                />
                            </div>
                            </div>}
                            <div className="mt-3 allcontest">
                                {/* <button type="button" class="btn btn-success contest mt-3 mx-0"><img src={contest} alt="contest" /> CONTEST</button> */}
                                {/* <Link to="/stake"><button type="button" class="btn btn-success stakebtn  mt-3 ms-0"><img src={stakes} alt="contest" /> STAKE</button></Link> */}
                                <Link to="/earn"><button type="button" class="btn btn-success earnbtn  mt-3  ms-3"><img src={rocket} alt="contest" /> EARN</button></Link>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>


                </div>

            </div>
        </>
    );
}