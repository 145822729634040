
import { useState, useEffect, useRef } from 'react';
import rocketimg from "../assets/game/rocketimg.png"
import splashimage from "../assets/game/splash_img.png"
import { getAccount } from '../hooks/useAccount';
import { CreateGameTransactionHooks, getgametransdetails } from '../hooks/UseTransaction';
import { getGameAllTransactionInfoHooks } from "../hooks/UseTransaction";
import { GamePrediction, ToastGame, createOrderdetails } from '../hooks/useBot';
import toast from 'react-hot-toast';
import { style, iconTheme, position } from '../hooks/useToast';
import { UpdateGameSettingBalancehooks, getAllGameTransactionDetails, getGameSettingDetails } from '../hooks/UseUser';
import { checkAlreadyWon } from '../hooks/useDice';
import { toFixedNumber } from '../hooks/UseContract';

var loopgame;
var stopuseeff = false;
function Moongametwo(props) {

console.log('propssssss', props)

    const [btnDisabled, setBtnDisabled] = useState(false);

    const [isWin, setIswin] = useState(true);
    const [counter, setCounter] = useState(0);
    console.log('counterdata', counter)
    const [crashval, setCrashval] = useState([]);
    const [updatedcrashval, setUpdatedCrashval] = useState([]);
    const [randomstart, setRandomStart] = useState(true);
    const [randomgenerate, setRandomGenerate] = useState(0);
    const [initialrandomLimit, setInitialRandomList] = useState('')
    const currentinitialrandom = useRef(initialrandomLimit)
    const [randomlimit, setRandomLimit] = useState(0);
    const [useeffcount, setUseEffCount] = useState(0);
    const [status, setStatus] = useState(false)
    console.log("updatedcrashval", status)
    console.log('initialrandomLimit Idddddddd', initialrandomLimit)

    //venkatesh
    const [accountInfo, setAccountInfo] = useState("");
    const [onemonthwonInfo, setOneMonthWonInfo] = useState([]);
    const [oneweekwonInfo, setOneWeekWonInfo] = useState([]);

    //
    const [onemonthtransdata, setOneMonthTransData] = useState();
    const [oneweektransdata, setOneWeekTransData] = useState();


    // today
    const [start, setStart] = useState(true);
    const [stop, setStop] = useState(false);
    const [loopgamedata, setLoopgameData] = useState(0);
    console.log("state moongames", loopgamedata)
    const [wager, setWager] = useState(0);
    const [totalwager, setTotalWager] = useState(0);
    const [multipleBets, setMultipleBets] = useState(0)
    const [loss, setLoss] = useState(false);
    const [endgame, setEndgame] = useState(false)
    const [incrementbetcount, setIncrementBetCount] = useState(1);
    const [stopinterval, setStopInterval] = useState(true)
    const [resultdata, setResultData] = useState([])
    console.log('xcounteloss hehe 4324234', stopinterval)
    console.log('11 enter this endofstar', incrementbetcount)

    const [gamesetting, setGameSetting] = useState({})
    const [allgametransacdata, setAllGameTransData] = useState([]);
    const [toastId, setToastId] = useState('');
    const [calculatewager, setCalculateWager] = useState(0)
    const [wonamount, setWonAmount] = useState(0)
    console.log('toastIdtoastIdtoastId', toastId)
    // const [stopuseeff, setStopUseEff] = useState(true)
    console.log('props?.usergamedetails?._id', props?.usergamedetails?._id)


    useEffect(()=>{
        console.log('INTITIALRANDOMLIMIT', initialrandomLimit)
        currentinitialrandom.current = initialrandomLimit;
    },[initialrandomLimit]);


    useEffect(() => {
        console.log("props?.bet remain moon play start", props, props?.bet && props?.disabledmoonbtn, props?.calculatewager, props?.bet, props?.disabledmoonbtn, props?.bet && props?.disabledmoonbtn)
        if (props?.bet && props?.disabledmoonbtn) {
            // console.log("enter this distabled 111 dfasdfasds")
            if (props?.remainingbets == 1) {
                console.log('remain moon play start')
                setBtnDisabled(false)
            } else {
                let myTimeout = setTimeout(function () {
                    console.log("enter this distabled 111 dfasdfasds")
                    console.log('remain 111 moon play start')
                    setBtnDisabled(false)
                }, 2000);
            }

        } else {
            console.log("enter this distsdfabled 222 dfasdfasds")
            console.log('else remain moon play start')
            setBtnDisabled(true)
        }
        let remainingrolls = (props?.multiplebets - props?.remainingbets) + 1;
        console.log('remainingrolls', props?.multiplebets, props?.remainingbets, remainingrolls)
        setIncrementBetCount(remainingrolls);
        setWager(props?.wager)
        setCalculateWager(props?.calculatewager)
        setTotalWager(props?.totalwager)
        setMultipleBets(props?.multiplebets);
        getTransData()
    }, [props]);

    console.log('last check minmax cond', initialrandomLimit,randomlimit)

    const getTransData = async () => {
        try {
            let reqdata = { filter: 'personal', walletAddress: accountInfo, gameName: 'Moon' }
            let res = await getgametransdetails(reqdata);
            let result = res?.data?.data;
            let arr = []
            for (let i = 0; i < result?.length; i++) {
                console.log('props?.sadfasd?._id', props?.usergamedetails?._id, result[i].OrderId, props?.usergamedetails?._id == result[i].OrderId)
                if ((props?.usergamedetails?._id != undefined) && (props?.usergamedetails?._id == result[i].OrderId)) {
                    arr.push(result[i])
                }
            }
            console.log('arrsdata', arr)
            setResultData(arr)
        } catch (e) {

        }
    }


    useEffect(() => {
        // They useEffect call only playRocket , when you play the game that time useeffect call.
        console.log('333 enter which cond check minmax cond', stopuseeff)
        async function fetchData() {
            console.log('111 enter this useeeffect', onemonthwonInfo, onemonthwonInfo?.length > 0, status)
            if((onemonthwonInfo?.length > 0) || status) {
                CreateRandomLimit()
            }  
        }
        fetchData();
    }, [toastId]);

    const CreateRandomLimit = async () => {
        try {
            console.log('props?.Wager toastId', props?.wager, stopuseeff)
            if (!stopuseeff) {
                let data = await checkingData();
                console.log('datasgal-data', data?.length > 0)
                let initialsetrandom = 0;
                let limiteddata;
                if (data?.length > 0) {
                    console.log('afdadasrwer', data, data[0]['start'], data[1]['middle'], data[2]['stop'], data[0]['start'] == false, data[1]['middle'] == false, data[2]['stop'] == false, ((data[2]['stop'] == false) && data[1]['middle'] == false) && (data[0]['start'] == false))
                    if ((data[0]['start'] == false)) {
                        console.log('enter thsiss')
                        if (((data[2]['stop'] == false) && data[1]['middle'] == false) && (data[0]['start'] == false)) {
                            initialsetrandom = Math.floor(Math.random() * (3 - 0 + 0)) + 0;
                            console.log('enter random if create setrandom', initialsetrandom)
                        } else if ((data[1]['middle'] == false) && (data[0]['start'] == false)) {
                            initialsetrandom = Math.floor(Math.random() * (2 - 0 + 0)) + 0;
                            console.log('enter random else if create setrandom', initialsetrandom)
                        } else {
                            initialsetrandom = 0;
                            console.log('enter random else create setrandom', initialsetrandom)
                        }
                    }
                    console.log('asdfasrqw4we', initialsetrandom)
                    let conditiondata, randdata, randomlimitdata
                    console.log('23 asdfasrqw4we', initialsetrandom)
                    if ((initialsetrandom == 0 || initialsetrandom == 1 || initialsetrandom == 2)) {
                        console.log('354 asdfasrqw4we', initialsetrandom)
                        conditiondata = [{ min: 1, max: 30 }, { min: 31, max: 60 }, { min: 61, max: 100 }];
                        randdata = conditiondata[Number(initialsetrandom)];
                        console.log('start check minmax cond', conditiondata, randdata, randdata?.min, randdata?.max)
                        if (randdata) {
                            randomlimitdata = await genRand(randdata?.min, randdata?.max, 'random');
                            console.log("initialsetrandom check minmax cond", initialsetrandom, randdata, randomlimitdata)
                        }
                        console.log('props?.calculatewager', props?.calculatewager)
                        if (props?.calculatewager <= 1) {
                            limiteddata = await genRand(1.3, 1.6, 'limit');
                        } else {
                            let maxdata = (Number(props?.calculatewager) + 0.6)
                            limiteddata = await genRand(Number(props?.calculatewager) + 0.2, maxdata, 'limit');
                        }
                    }
                    console.log('randomlimitdata', randomlimitdata)
                    if (randomlimitdata) {
                        stopuseeff = true;
                        console.log('start check minmax checkconditon', data, data.length, ((data[0]['start'] != true) || (data[1]['middle'] != true) || (data[2]['stop'] != true)));
                        for (let i = 0; i < data?.length; i++) {
                            if ((data[0]['start'] != true) || (data[1]['middle'] != true) || (data[2]['stop'] != true)) {
                                // data[0]['start'] == false --> This condition false means you play again the game
                                console.log('enter which cond check minmax cond', initialsetrandom, randomlimitdata, 1 <= Number(randomlimitdata) && 30 >= Number(randomlimitdata),
                                    (31 <= Number(randomlimitdata) && 60 >= Number(randomlimitdata)), (61 <= Number(randomlimitdata) && Number(randomlimitdata) >= 100))
                                if (1 <= Number(randomlimitdata) && 30 >= Number(randomlimitdata)) {
                                    console.log('start scenario', data[0]['start'] == false)
                                    if ((data[0]['start'] == false)) {
                                        console.log('if enter condition 1 gamecond')
                                        setInitialRandomList(randomlimitdata)
                                    } else {
                                        console.log('else enter condition 1 gamecond')
                                        console.log("123 enter which cond check minmax cond", initialsetrandom, props?.calculatewager, limiteddata)
                                        setInitialRandomList(limiteddata)
                                    }

                                } else if (31 <= Number(randomlimitdata) && 60 >= Number(randomlimitdata)) {
                                    console.log('middle scenario', data[1]['middle'] == false, data[0]['start'] == false)
                                    if (((data[1]['middle'] == false) && (data[0]['start'] == false))) {
                                        console.log('if enter condition 2 gamecond')
                                        setInitialRandomList(randomlimitdata)
                                    }

                                    // else if (data[0]['start'] == false) {
                                    //     console.log('else enter condition 2 gamecond')
                                    //     CreateRandomLimit()
                                    // }

                                } else if (61 <= Number(randomlimitdata) && 100 >= Number(randomlimitdata)) {
                                    console.log('stop scenario', data[2]['stop'] == false, data[0]['start'] == false, data[1]['middle'] == false)
                                    if ((data[2]['stop'] == false) && (data[0]['start'] == false) && (data[1]['middle'] == false)) {
                                        console.log('if enter condition 3 gamecond')
                                        setInitialRandomList(randomlimitdata)
                                    }

                                    // else if ((data[0]['stop'] == false) && (data[0]['middle'] == false)) {
                                    //     console.log('else if enter condition 3 gamecond')
                                    //     CreateRandomLimit()
                                    // } else if ((data[0]['stop'] == false) && (data[0]['start'] == false)) {
                                    //     console.log('else if enter condition 3 gamecond')
                                    //     // initialsetrandom = 0;
                                    //     CreateRandomLimit()
                                    // }
                                }
                            } else {
                                console.log('enter this second condition')
                                setInitialRandomList(1)
                            }

                        }
                    }
                    console.log('enter this useeeffect')
                    stopuseeff = false;
                }

            }
        } catch (e) {
            console.log('createRandomLimit_err', e)
        }
    }

    const genRand = async (min, max, filter) => {
        if(filter == 'random') {
            console.log('min,max val', min, max)
            let randvalue = Math.floor(Math.random() * (max - min + 1)) + min;
            console.log('masdfasdfasdf', randvalue, min, max);
            return randvalue;
        } else if(filter== 'limit'){
            console.log('min,max val', min, max)
            let randvalue = Math.random() * (max - min) + min;
            console.log('limit', randvalue, min, max)
            return randvalue;
            

        }

    }

    const checkingData = async () => {
        try {
            let data = [{ 'start': 5 }, { 'middle': 35 }, { 'stop': 65 }];
            let result = [];
            console.log('data?.lengthdata?.lengthdata?.length', data?.length, Object.values(data)?.length, oneweekwonInfo)
            for (let i = 0; i < Object.values(data)?.length; i++) {
                console.log('Object.values(data)[i]', Object.keys(data[i])[0])
                let presrep = {
                    Multiplier: `${Object.values(data[i])[0]}x`,
                    isWin: true,
                    maxpayout: 1,
                    totalwager: 1
                }
                console.log('presresp_data', presrep)
                let checkalreadywon = await checkAlreadyWon(presrep, oneweekwonInfo, onemonthwonInfo, "Moon");
                console.log('checkalreadywon_checkalreadywon', checkalreadywon)
                if ((checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true)) {
                    console.log("111 Object.keys(data[i])[0] moongcheckup", { [Object.keys(data[i])[0]]: (checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true) })
                    result.push({ [Object.keys(data[i])[0]]: (checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true) });
                } else {
                    console.log("222 Object.keys(data[i])[0] moongcheckup", { [Object.keys(data[i])[0]]: false })
                    result.push({ [Object.keys(data[i])[0]]: false });
                }
            }
            console.log("initial moongcheckup", result);
            return result
        } catch (e) {

        }
    }






    useEffect(() => {
        (async () => {
            try {
                // randomlimit != 0  you didn't use this condition means, initial enter this page , automaticcally call finishinterval because you no transaction, so
                // this condition  oneweekwonInfo.length > 0 || onemonthwonInfo.length > 0 --> false

                // endgame --> game end only call the useeffect so, the game stop only call the function , endofstr --> setEndgame(true)
                console.log('234  enter this endofstar', randomlimit, randomlimit != 0, endgame, randomlimit != 0 && endgame)
                if (endgame) {
                    console.log("oneweekwonInfo.length > 0 || onemonthwonInfo.length > 0",onemonthtransdata, randomlimit, endgame, oneweekwonInfo.length > 0 || onemonthwonInfo.length > 0)
                    if (onemonthtransdata?.length > 0 || onemonthtransdata?.length > 0) {
                        let presrep = {
                            Multiplier: `${randomlimit}x`,
                            isWin: true,
                            maxpayout: counter,
                            totalwager: totalwager
                        }
                        console.log('prespresp data endgame',  presrep)
                        let checkalreadywon = await checkAlreadyWon(presrep, oneweekwonInfo, onemonthwonInfo, "Moon");
                        console.log("checkalreadywonmoongame", checkalreadywon)
                        if ((checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true)) {
                            // This condition didn't possible , 
                            //maybe enter this condition normal to win the game
                            console.log('useff dfasdfasds')
                            if (props?.remainingbets == 1) {
                                console.log('endgame moon play start')
                                FinishInterval()
                            } else {
                                let myTimeout = setTimeout(function () {
                                    console.log("not disabled FASDASFD 333 sssss")
                                    console.log('endgame 11 moon play start')
                                    setBtnDisabled(false);
                                }, 3000);
                                FinishInterval();
                            }

                        } else {
                            console.log('useff11 dfasdfasds')
                            console.log('endgame else moon play start')
                            FinishInterval()
                        }

                    } else {
                        let myTimeout = setTimeout(function () {
                            console.log("not disabled CCCC 333 sssss")
                            console.log('disabled moon play start')
                            setBtnDisabled(false);
                        }, 3000);
                        FinishInterval();
                    }
                }
            } catch (e) {
                console.log("useEffect_err", e)
            }
        })();
    }, [endgame, !endgame])


    useEffect(() => {
        getGameSettings();
        getAllGameTransactionData();
    }, [props])

    useEffect(() => {
        (async () => {
            const walletAddress = getAccount();
            setAccountInfo(walletAddress);
            let transc = await getTransactionDetails(walletAddress, 'random');
            if (transc?.oneweekdata?.length > 0) {
                OneWeekWonInfo(transc?.oneweekdata, 'random')
            }
            if (transc?.onemonthdata?.length > 0) {
                OneMonthWonInfo(transc?.onemonthdata, 'random')
            }
        }) ();

    }, [props])


    useEffect(() => {
        (async () => {
            const walletAddress = getAccount();
            setAccountInfo(walletAddress);
            let transc = await getTransactionDetails(walletAddress, 'normal');
            console.log("oneweekwonInfoZ_OneMonthWonInfo", transc);
            if (transc?.oneweekdata?.length > 0) {
                OneWeekWonInfo(transc?.oneweekdata, 'normal')
            }
            if (transc?.onemonthdata?.length > 0) {
                OneMonthWonInfo(transc?.onemonthdata, 'normal')
            }
        })();
    }, [gamesetting]);

    const getGameSettings = async () => {
        try {
            let res = await getGameSettingDetails();
            console.log('getGameSettings', res?.data)
            if (res?.data?.status == true) {
                let result = res?.data?.data[0];
                if (result?.Status == "Active") {
                    setGameSetting(result)
                }
            }
        } catch (e) {
            console.log("gameGameSettings_err", e)
        }
    }


    const getAllGameTransactionData = async () => {
        try {
            let res = await getAllGameTransactionDetails();
            if (res?.data?.status == true) {
                // All come won transaction only
                setAllGameTransData(res?.data?.alltranscdata)
            }
        } catch (e) {
            console.log("getAllTransactionData_err", e)
        }
    }
    // common func
    //this Moongame only two params pass
    const OneWeekWonInfo = async (transc, filter) => {
        try {
            if(filter == 'normal') {
                console.log("alert 111 path", props?.pathName, transc)
                let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, "Moon", gamesetting, allgametransacdata, props?.maxpayout);
                console.log("OneWeekmoonWonInfo", res)
                setOneWeekWonInfo(res);
            } else if(filter == 'random'){
                console.log("alert 111 path", props?.pathName, transc)
                let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, "Moon", gamesetting, allgametransacdata, props?.maxpayout);
                console.log("OneWeekmoonWonInfo", res)
                setOneWeekTransData(res);
            }

        } catch (err) {
            console.log("OneWeekWonInfo_err", err)
        }
    }

    // common func
    const OneMonthWonInfo = async (transc, filter) => {
        try {
            if(filter == 'normal') {
                console.log("alert 222 path", props?.pathName, transc)
                let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, "Moon", gamesetting, allgametransacdata, props?.maxpayout);
            console.log("OneMOnthmoonWonInfo", res)
            setOneMonthWonInfo(res)
            } else if(filter == 'random'){
                console.log("alert 222 path", props?.pathName, transc)
                 let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, "Moon", gamesetting, allgametransacdata, props?.maxpayout);
                console.log("OneMOnthmoonWonInfo", res)
                setOneMonthTransData(res)
            }
        } catch (e) {
            console.log("PredictionInfo_err", e)
        }
    }

    // common func
    const getTransactionDetails = async (address, filter) => {
        try {
            console.log('filter datasgal', filter)
            if (filter == 'random') {
                setStatus(false)
                let data = {
                    walletAddress: address,
                    gameName: "Moon"
                }
                let res = await getGameAllTransactionInfoHooks(data);
                console.log("random respond_data", res?.data)

                if (res?.data?.oneweekdata?.length > 0 || res?.data?.onemonthdata?.length > 0) {
                    setStatus(true)
                    return {
                        oneweekdata: res?.data?.oneweekdata?.length > 0 ? res?.data?.oneweekdata : [],
                        onemonthdata: res?.data?.onemonthdata?.length > 0 ? res?.data?.onemonthdata : []
                    }

                } else {
                    setStatus(true)
                    return {
                        oneweekdata: [],
                        onemonthdata: []
                    }
                }
            } else if (filter == 'normal') {

                let data = {
                    walletAddress: address,
                    gameName: "Moon"
                }
                let res = await getGameAllTransactionInfoHooks(data);
                console.log("normal respond_data", res?.data)

                if (res?.data?.oneweekdata?.length > 0 || res?.data?.onemonthdata?.length > 0) {
                    return {
                        oneweekdata: res?.data?.oneweekdata?.length > 0 ? res?.data?.oneweekdata : [],
                        onemonthdata: res?.data?.onemonthdata?.length > 0 ? res?.data?.onemonthdata : []
                    }

                } else {
                    return {
                        oneweekdata: [],
                        onemonthdata: []
                    }
                }
            }

        } catch (e) {
            console.log("getTransactionDetails_err", e)
        }
    }

    // const [theArray, setTheArray] = useState(initialArray);
    const FinishInterval = async () => {
        try {
            if (incrementbetcount <= multipleBets) {
                setEndgame(false)
                let multiplier = wonamount;
                // if (calculatewager < counter) {
                //     multiplier = counter - calculatewager
                // }
                console.log("calc_profiet", counter, calculatewager, multiplier, calculatewager < counter)
                let presresp;
                console.log("lossgamewage", loss, calculatewager, counter)
                if (loss) {   // loss true means you loss the game, loss false means you won game;
                    presresp = {
                        Multiplier: "0x",
                        isWin: false,
                        maxpayout: calculatewager,// win or lose amount,
                        totalwager: totalwager
                    }
                } else {
                    presresp = {
                        Multiplier: `${counter}x`,
                        isWin: true,
                        maxpayout: multiplier,
                        totalwager: totalwager
                    }
                }
                if (presresp) {
                    let data = {}
                    if (presresp?.isWin == true) {
                        props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
                            if (((Number(Object.keys(val)[0].split('x')[0]) <= Number(((presresp?.Multiplier).split('x')[0]))) && (Number((Object.keys(val)[0].split('x')[1]).split('-')[1]) >= Number(((presresp?.Multiplier).split('x')[0]))))) {
                                if (presresp?.isWin == true) {
                                    data = {
                                        walletAddress: accountInfo,
                                        transaction: presresp,
                                        gameName: props?.pathName,
                                        Bets: multipleBets,
                                        Wager: props?.wager,
                                        CalculateWager: props?.calculatewager,
                                        TotalWager: props?.totalwager,
                                        Amount: presresp?.maxpayout,
                                        OrderId: props?.usergamedetails?._id
                                    }
                                } else if (presresp?.isWin == false) {
                                    data = {
                                        walletAddress: accountInfo,
                                        transaction: presresp,
                                        gameName: props?.pathName,
                                        Bets: multipleBets,
                                        Wager: props?.wager,
                                        CalculateWager: props?.calculatewager,
                                        TotalWager: props?.totalwager,
                                        Amount: presresp?.maxpayout,
                                        OrderId: props?.usergamedetails?._id
                                    }
                                }
    
                            } else if ("0x" == String(presresp?.Multiplier)) {
                                data = {
                                    walletAddress: accountInfo,
                                    transaction: presresp,
                                    gameName: props?.pathName,
                                    Bets: multipleBets,
                                    Wager: props?.wager,
                                    CalculateWager: props?.calculatewager,
                                    TotalWager: props?.totalwager,
                                    Amount: presresp?.maxpayout,
                                    OrderId: props?.usergamedetails?._id
                                }
                            }
                        })
    
                    } else if (presresp?.isWin == false) {
                        if ("0x" == String(presresp?.Multiplier)) {
                            data = {
                                walletAddress: accountInfo,
                                transaction: presresp,
                                gameName: props?.pathName,
                                Bets: multipleBets,
                                Wager: props?.wager,
                                CalculateWager: props?.calculatewager,
                                TotalWager: props?.totalwager,
                                Amount: presresp?.maxpayout,
                                OrderId: props?.usergamedetails?._id
                            }
                        }
                    }
                    let lossamount = 0, gainamount = 0;
                    var resultamount = 0;
                    console.log('props?.gamepercentage', props?.gamepercentage)
                    props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
                        console.log('valllgamepercentage', val, String(presresp?.Multiplier), Object.keys(val), Object.values(val)[0], String(Object.keys(val)) == String(presresp?.Multiplier), String(Object.keys(val)[0]) == String(presresp?.Multiplier));
                        if (((Number(Object.keys(val)[0].split('x')[0]) <= Number(((presresp?.Multiplier).split('x')[0]))) && (Number((Object.keys(val)[0].split('x')[1]).split('-')[1]) >= Number(((presresp?.Multiplier).split('x')[0]))))) {
                            if (presresp?.isWin == false) {
                                lossamount = presresp?.maxpayout;
                                resultamount = presresp?.maxpayout;
                            } else {
                                console.log("Number(Object.values(val)[0])", Number(Object.values(val)[0]))
                                gainamount = presresp?.maxpayout;
                                resultamount = presresp?.maxpayout;
                            }
                        } else if ("0x" == String(presresp?.Multiplier)) {
                            if (presresp?.isWin == false) {
                                lossamount = presresp?.maxpayout;
                                resultamount = presresp?.maxpayout;
                            } else {
                                console.log("Number(Object.values(val)[0])", Number(Object.values(val)[0]))
                                gainamount = presresp?.maxpayout;
                                resultamount = presresp?.maxpayout;
                            }
                        }
    
                    })
    
                    console.log("presrepdatamoongame", presresp, resultamount)
                    let res = await CreateGameTransactionHooks(data);
                    console.log("orderdata ruru1111", res);
    
    
                    if (res?.status == 200) {
                        let createres = await createOrderdetails(props?.pathName, props?.wager, props?.remainingbets, props?.betamount, (props.calculatewager * props?.multiplebets), accountInfo, props.stopgain, props.stoploss, gainamount, lossamount,props?.maxpayout, props?.calculatewager, props?.totalwager, '', '');
                        console.log('createres', props?.remainingbets, createres)
                        let result = createres?.data?.data;
                        if (createres?.status == 200) {
                            if (createres?.data?.status == true) {
                                if (result?.RemainingBets == 0) {
                                    let reqData = { _id: result?._id, walletAddress: accountInfo }
                                    let res = await UpdateGameSettingBalancehooks(reqData);
                                    if(res?.data?.status == true) {
                                        setTimeout(() => {
                                            props?.setFeeData()
                                        }, 3000)
                                    }
                                    console.log("update balance 212", res)
    
                                }
                            }
                            if (props?.remainingbets == 1) {
                                setTimeout(function () {
                                    props?.checkOrder()
                                    props.onCheck()
                                }, 4000);
                                setRandomLimit(0)
                            } else {
                                props?.checkOrder()
                                props.onCheck()
                                setRandomLimit(0)
                            }
    
    
                        }
                    }

                    if (presresp?.isWin == false) {
                        let resultamounts =
                        Number(toFixedNumber(Number(resultamount))).toFixed(4) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(4) : Number(toFixedNumber(Number(resultamount))).toFixed(5) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(5) :
                          Number(toFixedNumber(Number(resultamount))).toFixed(6) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(6) : Number(toFixedNumber(Number(resultamount))).toFixed(7) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(7) : Number(toFixedNumber(Number(resultamount))).toFixed(8) > 0 ?
                            Number(toFixedNumber(Number(resultamount))).toFixed(8) : Number(toFixedNumber(Number(resultamount))).toFixed(9) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(9) :
                              Number(toFixedNumber(Number(resultamount))).toFixed(10) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(10) :
                                Number(toFixedNumber(Number(resultamount))).toFixed(11);
                        setTimeout(function () {
                            toast.error(incrementbetcount + "/" + multipleBets + " " + "  You Lose  " + " " + "-" + resultamounts, {
                                position: position.position,
                                style: style,
                                iconTheme: iconTheme,

                            })
                            console.log('11 stopinterval_true', stopinterval)
                            setStopInterval(true)
                            setStatus(false)
                        }, 1000)
                        setTimeout(() => {
                            console.log("enter this 56 111 dfasdfasds")
                            console.log('false moon play start')
                            setBtnDisabled(false)
                        }, 4000)
                    } else {
                        let resultamounts =
                        Number(toFixedNumber(Number(resultamount))).toFixed(4) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(4) : Number(toFixedNumber(Number(resultamount))).toFixed(5) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(5) :
                          Number(toFixedNumber(Number(resultamount))).toFixed(6) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(6) : Number(toFixedNumber(Number(resultamount))).toFixed(7) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(7) : Number(toFixedNumber(Number(resultamount))).toFixed(8) > 0 ?
                            Number(toFixedNumber(Number(resultamount))).toFixed(8) : Number(toFixedNumber(Number(resultamount))).toFixed(9) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(9) :
                              Number(toFixedNumber(Number(resultamount))).toFixed(10) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(10) :
                                Number(toFixedNumber(Number(resultamount))).toFixed(11);
                        setTimeout(function () {
                            toast.success(incrementbetcount + "/" + multipleBets + " " + "  You Victory  " + " " + "+" + resultamounts, {
                                position: position.position,
                                style: style,
                                iconTheme: iconTheme,
                            })
                            console.log('true moon play start')
                            console.log('22 stopinterval_true', stopinterval)
                            setStopInterval(true)
                            setStatus(false)
                        }, 1000)
                        setTimeout(() => {
                            console.log("enter this 35 111 dfasdfasds")
                            console.log('setimour play start')
                            setBtnDisabled(false)
                        }, 4000)

                    }
                }
    
    
    
    
                let sum = incrementbetcount + 1;
                setIncrementBetCount(sum);
            }
          
        } catch (e) {
            console.log("FinishInterval_err", e)
        }
    }


    
    function stars() {
        try {
            let count = 50;
            let scene = document.querySelector('.scene');
            let i = 0;
            while (i < count) {
                let star = document.createElement('i');
                let x = Math.floor(Math.random() * window.innerWidth);

                let duration = Math.random() * 1;
                let h = Math.random() * 100;

                star.style.left = x + 'px';
                star.style.width = 1 + 'px';
                star.style.height = h + 'px';
                star.style.animationDuration = duration + 's';

                scene.appendChild(star);
                i++;
            }
            console.log("1st scene_data roro", scene)
        } catch (e) {
            console.log("stars_err", e)
        }

    }


    var arr = [800];
    const generateRandom = async () => {
        try {
            console.log('generateRandomcheck',incrementbetcount <= multipleBets, currentinitialrandom.current)
            if(incrementbetcount <= multipleBets) {
                document.getElementById("img_rocket").setAttribute("src", rocketimg);
                document.querySelector('.rocket').style.top = "-130px";
                console.log("generaterandom dfasdfasds")
                console.log('genertat moon play start')
                setBtnDisabled(true);
                // let rand = Math.random() * maxLimit;
                // console.log("rand_rand", rand); // say 99.81321410836433
                // rand = Math.floor(rand);
                // rand = rand.toFixed(2);
                // console.log("randlimitdata moongames", rand)
                // setRandomLimit(3)
    
                // for (let i = 0; i < 3; i++) {
                //     let randomlygenerate = Math.floor(Math.random() * (300 - 100 + 1)) + 100;
                //     arr.push(randomlygenerate)
                // }
                // setIntervalLoop(0, 3, 0)
                console.log('randomlimit', initialrandomLimit)
                for (let i = 0; i < currentinitialrandom.current; i++) {
                    let randomlygenerate = Math.floor(Math.random() * (300 - 100 + 1)) + 100;
                    arr.push(randomlygenerate)
                }
                setRandomLimit(currentinitialrandom.current)
                setIntervalLoop(0, currentinitialrandom.current, 0)
            }

        } catch (e) {
            console.log("generateRandom_err", e)
        }
    }



    var index = 0;
    var interval = 800;

    var count1 = 0;

    const setIntervalLoop = (count, rand, index) => {
        try {
            console.log('arrrrrrrrr incr', arr, count, rand, count <= rand, stopinterval)
            // loopgame = setInterval(function () {
            if (stopinterval) {
                loopgame = setInterval(async () => {
                    console.log("enter intervalloop 1111 moongames", loopgame, count <= rand)
                    setLoopgameData(loopgame)
                    // automatically blast scenario
                    if (count <= rand) {
                        count += 0.1;
                        console.log("count incr", count)
                        document.getElementById("randomrocketx").innerHTML = count.toFixed(2) + "x";
                        count1 = count1 + 1;
                        setCounter(count);
                        console.log("count, rand, index kjkj incr", count, rand, index, interval, count <= rand, count1, count1 >= 5)
                        if (count1 >= 5) {
                            count1 = 0;
                            console.log("index data incr", index, arr[index], index + 1)
                            index = arr[index] != undefined ? index + 1 : 1;
                            console.log("count1 kjkj incr", arr[index], count1, index, arr[index])
                            interval = arr[index] != undefined ? arr[index] : arr[0];
                            console.log("intervaldata incr", interval)
                            clearInterval(loopgame)
                            console.log("234 count, rand, index incr", count, rand, index, interval)
                            setIntervalLoop(count, rand, index)
                        }
                    } else {
                        console.log('countdata 234', count.toFixed(2), loopgame)
                        await Stop(crashval, count, loopgame, `You Lost ${calculatewager.toFixed(4)}`)

                    }
                    console.log("indexdata", count1, interval, index)
                }, interval);
            }

        } catch (e) {
            console.log("setIntervalLoop_err", e)
        }

    };

    console.log("var loops moongames", loopgame)


    const Stop = async (crashval, count, loopgame, resultstring) => {
        try {
            console.log("stopgame 2222 moongames", crashval, count, loopgame, resultstring)
            if (resultstring != undefined) {
                clearInterval(loopgame);
                console.log('11 stopinterval_false', stopinterval)
                setStopInterval(false)
                setCrashval([...crashval, count.toFixed(2)]);
                endOfStar(false, resultstring);
                setLoss(true)

            } else {
                // let percentage = count * 100 / Number(calculatewager);
                // console.log("percentage condition", percentage >= 100)




                // if (percentage >= 100) {
                //     let countdata = count;
                //     setCrashval([...crashval, count.toFixed(2)]);
                //     clearInterval(loopgame);
                //     console.log('11 stopinterval_false', stopinterval)
                //     setStopInterval(false)
                //     endOfStar(true, `You got amount ${countdata.toFixed(4)}`);
                //     setIswin(true)
                //     setLoss(false)
                //     console.log("true countdatas", countdata, loopgame)
                // } else {
                //     let losspercentage = 100 - Number(percentage);
                //     // let lossamount = wager - counter;
                //     // defaultentha tha uduhtthruntha
                //     let lossamount = calculatewager - counter;
                //     console.log("lossamountdata", lossamount, calculatewager, counter)
                //     setCrashval([...crashval, count.toFixed(2)]);
                //     clearInterval(loopgame);
                //     console.log('11 stopinterval_false', stopinterval)
                //     setStopInterval(false)
                //     // endOfStar(false, `Bet to loss your amount ${lossamount.toFixed(4)}`);
                //     endOfStar(false, `Bet to loss your amount ${calculatewager.toFixed(4)}`);
                //     setIswin(false)
                //     setLoss(true)
                //     console.log("false countdatas", losspercentage, loopgame)
                // }

                // Scenario vise everytime user won the game
                console.log('calculatewager', calculatewager, count);
                let getpercentage;
                props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val) => {
                    console.log('valuedatassss', val, count, Number(Object.keys(val)[0].split('x')[0]), Number((Object.keys(val)[0].split('x')[1]).split('-')[1]), (Number(Object.keys(val)[0].split('x')[0]) < count) && (Number((Object.keys(val)[0].split('x')[1]).split('-')[1]) > count))
                    if ((Number(Object.keys(val)[0].split('x')[0]) < count) && (Number((Object.keys(val)[0].split('x')[1]).split('-')[1]) > count)) {
                        console.log('Number(Object.keys(val)[0', val, Number(Object.values(val)[0]), count);
                        getpercentage = Number(Object.values(val)[0]);
                    } 
                })
                let profitamount;
                if (getpercentage != undefined) {
                    let percentage = Number(calculatewager) * Number(getpercentage) / 100;
                     profitamount = Number(calculatewager) + Number(percentage);
                     console.log("percentage_percentage", percentage)
                } else {
                    profitamount = Number(calculatewager);
                }
                console.log('percentar+profi', calculatewager, getpercentage, profitamount, calculatewager < profitamount)
                setWonAmount(profitamount)
                //Definitely this way highest than calculatewager
                if (calculatewager < profitamount) {
                    let countdata = count;
                    setCrashval([...crashval, count.toFixed(2)]);
                    clearInterval(loopgame);
                    console.log('11 stopinterval_false', stopinterval)
                    setStopInterval(false)
                    endOfStar(true, `You got amount ${profitamount.toFixed(4)}`);
                    setIswin(true)
                    setLoss(false)
                    console.log("true countdatas", countdata, loopgame)
                } else {
                    // let lossamount = wager - counter;
                    // defaultentha tha uduhtthruntha
                    let lossamount = calculatewager - counter;
                    console.log("lossamountdata", lossamount, calculatewager, counter)
                    setCrashval([...crashval, count.toFixed(2)]);
                    clearInterval(loopgame);
                    console.log('11 stopinterval_false', stopinterval)
                    setStopInterval(false)
                    // endOfStar(false, `Bet to loss your amount ${lossamount.toFixed(4)}`);
                    endOfStar(false, `Bet to loss your amount ${calculatewager.toFixed(4)}`);
                    setIswin(false)
                    setLoss(true)
                    console.log("false countdatas", loopgame)
                }


            }


        } catch (e) {
            console.log("Stop_err", e)
        }
    }


    function endOfStar(isWin, result) {
        try {
            console.log("end of str hehe", isWin, result);
            if (isWin) {
                setTimeout(() => {
                    document.getElementById("randomrocket").innerHTML = '';
                    document.getElementById("randomrocket").classList.remove("loss_text_rocket");

                    document.getElementById("randomrocket").classList.add("won_text_rocket");
                }, 3000);


                document.getElementById("randomrocket").innerHTML = result;
                document.getElementById("randomrocket").classList.remove("loss_text_rocket");
                document.getElementById("randomrocket").classList.add("won_text_rocket");

            }
            else {
                setTimeout(() => {
                    document.getElementById("randomrocket").innerHTML = '';
                    document.getElementById("randomrocket").classList.remove("won_text_rocket");
                    document.getElementById("randomrocket").classList.add("loss_text_rocket");
                }, 3000);

                document.getElementById("randomrocket").innerHTML = result;
                document.getElementById("randomrocket").classList.remove("won_text_rocket");
                document.getElementById("randomrocket").classList.add("loss_text_rocket");

            }

            document.getElementById("img_rocket").setAttribute("src", splashimage);
            document.querySelector('.rocket').style.top = "-130px";

            var starrdiv = document.querySelectorAll(".scene i");
            for (var j = 0; j < starrdiv.length; j++) {
                starrdiv[j].style.display = "none";

            }

            setTimeout(function () {
                document.getElementById("randomrocket").classList.remove("loss_text_rocket");
                document.getElementById("randomrocket").classList.remove("won_text_rocket");


                document.getElementById("img_rocket").setAttribute("src", rocketimg);
                console.log("enter this distsdfabled 111 dfasdfasds")
                console.log('enodstr moon play start')
                setBtnDisabled(false);
                document.getElementById("randomrocketx").innerHTML = "00.00" + "x";
                document.querySelector('.rocket').style.top = "0px";



            }, 3000);

            // document.querySelector('.rocket').style.top = "-130px";
            console.log('enter this endofstar')
            setStart(true)
            setEndgame(true)
        } catch (e) {
            console.log("endOfStar_err", e)
        }


    }

    const playRocket = async () => {
        try {
            let Id = await ToastGame('Moon', "", "loading");
            setToastId(Id);
            console.log('234 Idddddddd', Id, initialrandomLimit,currentinitialrandom.current)
            if(Id){
                setTimeout(() => {
                    console.log('Idddddddd', Id, initialrandomLimit,currentinitialrandom.current)
                    if (currentinitialrandom.current) {
                        console.log("play the rocket 1111 moongames", multipleBets, incrementbetcount)
                        if (incrementbetcount <= multipleBets) {
                            stars();
                            generateRandom();
                            document.querySelector('.rocket').style.top = - 100 + 'px';
                            document.querySelector('.rocket').style.animationDuration = 2 + 's';
                            // setStatus(!status)
                            setStart(false)
                        } else {
                            console.log("enter this 35 334 dfasdfasds")
                            console.log('plasyrocket moon play start')
                            setBtnDisabled(true)
                        }
                    }
                }, 5000)
            }
            console.log('moon play start')
            setBtnDisabled(true)

        } catch (e) {
            console.log("playRocket_err", e)
        }
    }








    return (


        <div className='container game_container_limboo'>

            <div className='flex_row_badge flex_row_badge_moon'>

                {/* <p>crashval--</p> */}

                {resultdata && resultdata?.length > 0 && resultdata?.map((val, index) => {
                    if(index <= 7) {
                        return(
                            <>
                            {console.log("valdata", val, isWin, index)}
                            <div className={val?.isWin ? "badge bange_green active" : "badge bange_red active"} key={index}>
                                <div className='bet_cirle'>
    
                                </div>
                                <span>
                                    {Number(val?.MaxPayOut).toFixed(2)}
                                </span>
                            </div>
                        </>
                        )
                    }
                }

                )}


                {/* <div className='badge bange_grey active'>
                            <div className='bet_cirle'>

                            </div>
                            <span>
                                1.21x
                            </span>
                        </div>
                        <div className='badge bange_grey'>
                            <div className='bet_cirle'>

                            </div>
                            <span>
                                1.34x
                            </span>
                        </div>
                        <div className='badge bange_grey active'>
                            <div className='bet_cirle'>

                            </div>
                            <span>
                                1.21x
                            </span>
                        </div>
                        <div className='badge bange_grey'>
                            <div className='bet_cirle'>

                            </div>
                            <span>
                                1.34x
                            </span>
                        </div>
                        <div className='badge bange_grey active'>
                            <div className='bet_cirle'>

                            </div>
                            <span>
                                1.21x
                            </span>
                        </div>
                        <div className='badge bange_grey'>
                            <div className='bet_cirle'>

                            </div>
                            <span>
                                1.34x
                            </span>
                        </div>
                        <div className='badge bange_grey active'>
                            <div className='bet_cirle'>

                            </div>
                            <span>
                                1.21x
                            </span>
                        </div> */}





            </div>




            <div id="gameContainer" className='gameContainer_moon_new'>
                <div class="scene">
                    <p id="randomrocketx"></p>
                    <p id="randomrocket"></p>


                    <div class="rocket" id="rocketdiv">
                        <img src={rocketimg} width="128" height="128" alt="" id="img_rocket" />
                    </div>
                </div>
            </div>

            {/* <div id="gameContainer" className='gameContainer_moon_new pt-0 d-flex comingsoon justify-content-center align-items-center'>

    <div className='pt-0'>
    <h1 className='pt-0'>Coming Soon..</h1>
    </div>
    </div> */}




            <div id="buttonContainer" className='text-center'>
                {console.log("moondis", btnDisabled)}
                {start ? <button onClick={() => { playRocket(); setEndgame(false); }} id="rocketbtn" disabled={btnDisabled} className='btn btn-warning wallet play mt-4'>Start Game</button> :
                    <button id="rocketbtn" className='btn btn-warning wallet play mt-4' onClick={async () => { setStop(true); console.log('loopgameeee moongames', loopgamedata, loopgame); clearInterval(loopgame); await Stop(crashval, counter, loopgamedata) }}>Stop</button>}
                {/* <button id="takeProfits">Take Profits</button>
                <label for="betAmount">Bet Amount:</label>
                <input type="number" id="betAmount" value="1" /> */}
            </div>

            <div id="infoContainer">
                <div>
                    <p id="balance"></p>
                    {/* <p id="crashedAt">Multiplier at crash: <span id='crash_value'></span></p> */}
                </div>

                <div id="betContainer">
                </div>
            </div>


        </div>
    );
}

export default Moongametwo;
