
import { useState, useEffect } from 'react';
// import './App.css';

import vsImage from "../assets/vs.png"

import queImage from "../assets/question.png"
import { getAccount } from '../hooks/useAccount';
import { CreateGameTransactionHooks } from '../hooks/UseTransaction';
import { getGameAllTransactionInfoHooks } from "../hooks/UseTransaction";
import { createOrderdetails, GamePrediction } from '../hooks/useBot';
import { checkAlreadyWon, DiceGameCondition } from '../hooks/useDice';
import { style, iconTheme, position } from '../hooks/useToast';
import toast from 'react-hot-toast';
import { getAllGameTransactionDetails, getGameSettingDetails, UpdateGameSettingBalancehooks } from '../hooks/UseUser';
import { toFixedNumber } from '../hooks/UseContract';





function Rps(props) {



    const [btnDisabled, setRpcBtnDisabled] = useState(false);
    const [rpsSelected, setRpsSelected] = useState(1);
    const [rpsResult, setRpsResult] = useState(0);
    const [rpsresultstatus, setRpsResultStatus] = useState(false)
    const [rpsWin, setRpsWin] = useState("Tie");
    const [selectedRpc, setSelectedRpc] = useState("Stone");
    console.log("selectedRpc_selectedRpc_rpsgame", props?.selectedRpc,rpsResult,btnDisabled)
    const [multipleBets, setMultipleBets] = useState('');
    const [maxpayout, setMaxPayout] = useState(0);
    const [totalwager, setTotalWager] = useState(0);
    const [onemonthwonInfo, setOneMonthWonInfo] = useState([]);
    const [oneweekwonInfo, setOneWeekWonInfo] = useState([]);
    const [incrementbetcount, setIncrementBetCount] = useState(1);
    const [transactionInfo, setTransactionInfo] = useState([]);
    const [accountInfo, setAccountInfo] = useState("");
    const [check, setCheck] = useState(false);
    const [roatedcount, setRoatedCount] = useState(0);
    const [rpsbet, setRpsbet] = useState(false)
    const [gamesetting, setGameSetting] = useState({})
    const [allgametransacdata, setAllGameTransData] = useState([]);
    const [allowin, setAllowin] = useState(false);
    const [play, setPlay] = useState(false)


    const [status, setStatus] = useState(false)


console.log("rpsadfasdfas", btnDisabled, selectedRpc, rpsSelected)
    const enemyConfig = {
        1: 3,
        2: 1,
        3: 2
    }

    useEffect(() => {
        console.log(rpsResult, enemyConfig[rpsResult], rpsSelected, "demo_demo")

        if (enemyConfig[rpsResult] == rpsSelected) {
            setRpsWin("Win")
        } else if (rpsResult == rpsSelected) {
            setRpsWin("Tie")
        } else {
            setRpsWin("Lose")
        }
    }, [rpsResult, rpsSelected])


    // const myTimeout = setTimeout(myGreeting, 5000);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
        // console.log('mssssssssss', ms)
      );



    useEffect(() => {
        if (props?.bet && props?.disabledRpcbtn) {
            if(!play) {
                setRpcBtnDisabled(false)
            }

            setRpsbet(true)
            props?.selectedRpc == '' ? setSelectedRpc('Stone') : setSelectedRpc(props?.selectedRpc);
            props?.selectedRpc == '' ? setRpsSelected(1) : props?.selectedRpc == 'Stone' ? setRpsSelected(1) : props?.selectedRpc == 'Scissor' ? setRpsSelected(2) : setRpsSelected(3)
        } else {
            props?.setRpcStart(true)
            setRpcBtnDisabled(true)
            console.log('props?.bet && props?.disabledRpcbtn condition disabled true', props?.selectedRpc, props?.selectedRpc == '')
            // props?.selectedRpc == '' ? setSelectedRpc('Stone') : setSelectedRpc(props?.selectedRpc);
            // props?.selectedRpc == '' ? setRpsSelected(1) : props?.selectedRpc == 'Stone' ? setRpsSelected(1) : props?.selectedRpc == 'Scissor' ? setRpsSelected(2) : setRpsSelected(3)

            rpsbet ? setRpsbet(true) : setRpsbet(false);
            rpsbet ? selectedRpc ? setSelectedRpc(selectedRpc) : props?.selectedRpc == '' ? setSelectedRpc('Stone') : setSelectedRpc(props?.selectedRpc) : setSelectedRpc("Stone")
            rpsbet ? rpsSelected ? setRpsSelected(rpsSelected) : props?.selectedRpc == '' ? setRpsSelected(1) : props?.selectedRpc == 'Stone' ? setRpsSelected(1) : props?.selectedRpc == 'Scissor' ? setRpsSelected(2) : setRpsSelected(3) : setRpsSelected(1)
            // setRpsResult(0)
        }
    }, [props]);


    useEffect(() => {
        console.log("props?.bet && props?.disabledRpcbtnfasdfasdfas",props?.bet,  props?.disabledRpcbtn,selectedRpc,props?.maxpayout, props?.selectedRpc,props?.bet && props?.disabledRpcbtn)
        setMultipleBets(props?.multiplebets);
        props.InitialSelectedRpc(selectedRpc);
        setMaxPayout(props?.maxpayout);
        setTotalWager(props.totalwager);
        let remainingrolls = (props?.multiplebets - props?.remainingbets) + 1;
        setIncrementBetCount(remainingrolls);
        if (props?.remainingbets == 0) {
            setRoatedCount(0)
        }
    }, [selectedRpc, props]);


    useEffect(() => {
        async function fetchData() {
            let gamedata = {
                predictRpc: 1,
                resultRpc: 2,
                StoneRps: 0,
                ScissorRps: 1,
                PaperRps: 0
            }
            let dicecondition = await DiceGameCondition(gamedata, props?.pathName, {}, props?.gameInfo?.GameCondition);
            console.log("condition return", dicecondition);
            let presresp = await GamePrediction(dicecondition, [], props?.calculatewager, props?.multiplebets, totalwager, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
            console.log("useEffect check presresp", presresp);
            let checkalreadywon = await checkAlreadyWon(presresp, oneweekwonInfo, onemonthwonInfo, "RPC");
            console.log("initally checkalreadywon", checkalreadywon);
            if ((checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true)) {
                setAllowin(false)
              } else {
                setAllowin(true)
              }
        }
        fetchData()

    }, [selectedRpc, props, rpsResult]);

    useEffect(() => {
        (async () => {

            try {
                const walletAddress = getAccount();
                setAccountInfo(walletAddress);
                let transc = await getTransactionDetails(walletAddress);
                setCheck(false);
                console.log("oneweekwonInfoZ_OneMonthWonInfo", transc);
                if (transc?.oneweekdata?.length > 0) {
                    OneWeekWonInfo(transc?.oneweekdata)
                }
                if (transc?.onemonthdata?.length > 0) {
                    OneMonthWonInfo(transc?.onemonthdata)
                }

            } catch (e) {
                console.log('rpsgame useffect err', e)
            }
        })();
    }, [selectedRpc, gamesetting])


    useEffect(() => {
        getGameSettings();
        getAllGameTransactionData();
      }, [props])

    const getTransactionDetails = async (address) => {
        let data = {
            walletAddress: address,
            gameName: "RPC"
        }
        let res = await getGameAllTransactionInfoHooks(data);
        console.log("respond_data", res)
        if (res?.status == 200) {
            if (res?.data?.alltranscdata?.length > 0) {
                 //That is useless state , just store all transactiondata
                setTransactionInfo(res?.data?.alltranscdata)
            }
        }

        if (res?.data?.oneweekdata?.length > 0 || res?.data?.onemonthdata?.length > 0) {
            return {
                oneweekdata: res?.data?.oneweekdata?.length > 0 ? res?.data?.oneweekdata : [],
                onemonthdata: res?.data?.onemonthdata?.length > 0 ? res?.data?.onemonthdata : []
            }
        } else {
            return {
                oneweekdata: [],
                onemonthdata: []
            }
        }
    }


    useEffect(() => {
        (async () => {
            try {
                console.log('rpsResult_data', rpsResult >= 1,rpsResult, incrementbetcount);
                if (rpsResult >= 1) {
                    console.log('enter this time rpsResult_data', rpsResult >= 1, onemonthwonInfo)
                    if (oneweekwonInfo.length > 0 || onemonthwonInfo.length > 0) {
                        let gamedata = {
                            predictRpc: selectedRpc == "Stone" ? 1 : selectedRpc == "Scissor" ? 2 : 3,
                            resultRpc: rpsResult,
                            StoneRps: rpsResult == 1 ? 3 : 0,
                            ScissorRps: rpsResult == 2 ? 1 : 0,
                            PaperRps: rpsResult == 3 ? 2 : 0
                        }
                        console.log('rpsgamedata', gamedata)
                        let dicecondition = await DiceGameCondition(gamedata, props?.pathName, {}, props?.gameInfo?.GameCondition);
                        console.log("condition return", dicecondition);

                        let presresp = await GamePrediction(dicecondition, [], props?.calculatewager, props?.multiplebets, totalwager, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
                        console.log("presresp data", presresp);

                        let checkalreadywon = await checkAlreadyWon(presresp, oneweekwonInfo, onemonthwonInfo, "RPC");
                        console.log("checkalreadywon", checkalreadywon)

                        if ((checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true)) {
                            // setRpsResult(0)
                            // setSelectedRpc("Stone")
                            console.log('rps game never ever come this console')
                            let roatedCount = roatedcount - 1;
                            setRoatedCount(roatedCount);
                            await playrps(roatedCount);
                        } else {
                            setTimeout(() => {
                                setStatus(false)
                                FinishInterval()
                            }, 3500)
                        }
                    } else {
                        console.log("No transaction data" + " " + props?.pathName + "finishInterval Initial call lele");
                        setTimeout(() => {
                            setStatus(false)
                            FinishInterval()
                        }, 3500)
                        
                    }
                    console.log('roatedcount >= props.multipleBets', roatedcount >= props.multipleBets, roatedcount, props.multipleBets)
                    if (roatedcount >= props.multipleBets) {
                        console.log('roatecd condition disabled true')
                        setRpcBtnDisabled(true)
                    }
                }
            } catch (e) {
                console.log('useEffect err', e)
            }

        })();
    }, [rpsresultstatus]);


    const OneMonthWonInfo = async (transc) => {
        try {
            let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0,  props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
            console.log("OneMonthWonInfo", res)
            setOneMonthWonInfo(res)
        } catch (e) {
            console.log("PredictionInfo_err", e)
        }
    }


    const OneWeekWonInfo = async (transc) => {
        try {
            let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
            console.log("OneWeekWonInfo", res)
            setOneWeekWonInfo(res);
        } catch (err) {
            console.log("OneWeekWonInfo_err", err)
        }
    }


    const randomNumber = async (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }



    const playrps = async (roatedcount) => {
        try {
            if (allowin) {
                let roatedCount = roatedcount + 1;
                console.log('roatedCount_roatedCount', roatedCount, multipleBets, roatedCount <= multipleBets)
                if (roatedCount <= multipleBets) {
                    console.log('playrps true condition disabled true')
                    setPlay(true);
                    setRpcBtnDisabled(true);
                    setRoatedCount(roatedCount);
                    console.log("rps");
                    var lpsResult = await randomNumber(1, 3);
                    console.log(lpsResult, "lpsResult_lpsResult");
                    setRpsResult(lpsResult);
                    setRpsResultStatus(!rpsresultstatus)

                    // demo()

                } else {
                    console.log('playrps false condition disabled true')
                    setRpcBtnDisabled(true);
                }
            } else {
                let roatedCount = roatedcount + 1;
                console.log('roatedCount_roatedCount',selectedRpc, roatedCount, multipleBets, roatedCount <= multipleBets)
                if (roatedCount <= multipleBets) {
                    setPlay(true);
                    setRpcBtnDisabled(true);
                    setRoatedCount(roatedCount);
                    console.log("disabled");
                    var lpsResult;
                    var result
                    if(selectedRpc == "Stone") {
                        result = await randomNumber(0, 1);
                        if(result == 0) {
                            lpsResult = 1
                        } else {
                            lpsResult = 3
                        }
                        // lpsResult = ;
                    } else if (selectedRpc == "Scissor") {
                        result = await randomNumber(0, 1);
                        if(result == 0) {
                            lpsResult = 2
                        } else {
                            lpsResult = 1
                        }
                    } else if (selectedRpc == "Paper") {
                        result = await randomNumber(0, 1);
                        if(result == 0) {
                            lpsResult = 3
                        } else {
                            lpsResult = 2
                        }
                    }
                    console.log(lpsResult, "lpsResult_lpsResult");
                    setRpsResult(lpsResult);
                    setRpsResultStatus(!rpsresultstatus)
                    setStatus(true)

                } else {
                    console.log("111 disabled");
                    setRpcBtnDisabled(true);
                }
            }

            // setRpsResultStatus(status)
        } catch (e) {
            console.log("playrps_Err", e)
        }

    }

    const getGameSettings = async () => {
        try {
            let res = await getGameSettingDetails();
            console.log('getGameSettings', res?.data)
            if (res?.data?.status == true) {
                let result = res?.data?.data[0];
                if (result?.Status == "Active") {
                    setGameSetting(result)
                }
            }
        } catch (e) {
            console.log("gameGameSettings_err", e)
        }
    }

    const getAllGameTransactionData = async () => {
        try {
            let res = await getAllGameTransactionDetails();
            if (res?.data?.status == true) {
                setAllGameTransData(res?.data?.alltranscdata)
            }
        } catch (e) {
            console.log("getAllTransactionData_err", e)
        }
    }

    const FinishInterval = async () => {
        try {
            if (incrementbetcount <= multipleBets) {
                let gamedata = {
                    predictRps: selectedRpc == "Stone" ? 1 : selectedRpc == "Scissor" ? 2 : 3,
                    resultRps: rpsResult,
                    StoneRps: rpsResult == 1 ? 3 : 0,
                    ScissorRps: rpsResult == 2 ? 1 : 0,
                    PaperRps: rpsResult == 3 ? 2 : 0
                };
    
                console.log("FinishInterval_gamedatas rpsgame game", incrementbetcount, gamedata, props?.maxpayout);
                let dicecondition = await DiceGameCondition(gamedata, props?.pathName, {}, props?.gameInfo?.GameCondition);
                console.log("result condition return rpsgame game", dicecondition, transactionInfo, props?.wager, props?.multiplebets, totalwager);
                let presresp = await GamePrediction(dicecondition, [], props?.calculatewager, props?.multiplebets, totalwager, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
                console.log("final presrep rpsgame game", presresp, dicecondition, totalwager);
    
                if (presresp) {
    
                    let data = {}
                    if(presresp?.isWin == true) {
                      props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
                        if (String(Object.keys(val)[0]) == String(presresp?.Multiplier)) {
                            let wonpercentage = Number(props?.calculatewager) * Number(Object.values(val)[0]) / 100;
                  let amount = Number(props?.calculatewager) + Number(wonpercentage);
                  console.log('multipliet data', wonpercentage, amount, Object.values(val)[0], props?.calculatewager)
                          if (presresp?.isWin == true) {
                            data = {
                              walletAddress: accountInfo,
                              transaction: presresp,
                              gameName: props?.pathName,
                              Bets: multipleBets,
                              Wager: props?.wager,
                              CalculateWager: props?.calculatewager,
                              TotalWager: props?.totalwager,
                              Amount: amount,
                              OrderId: props?.usergamedetails?._id
                            }
                          } else if (presresp?.isWin == false) {
                            data = {
                              walletAddress: accountInfo,
                              transaction: presresp,
                              gameName: props?.pathName,
                              Bets: multipleBets,
                              Wager: props?.wager,
                              CalculateWager: props?.calculatewager,
                              TotalWager: props?.totalwager,
                              Amount: presresp?.maxpayout,
                              OrderId: props?.usergamedetails?._id
                            }
                          }
            
                        } else if ("0x" == String(presresp?.Multiplier)) {
                          data = {
                            walletAddress: accountInfo,
                            transaction: presresp,
                            gameName: props?.pathName,
                            Bets: multipleBets,
                            Wager: props?.wager,
                            CalculateWager: props?.calculatewager,
                            TotalWager: props?.totalwager,
                            Amount: presresp?.maxpayout,
                            OrderId: props?.usergamedetails?._id
                          }
                        }
                      })
                 
                    } else if (presresp?.isWin == false) {
                      if ("0x" == String(presresp?.Multiplier)) {
                        data = {
                          walletAddress: accountInfo,
                          transaction: presresp,
                          gameName: props?.pathName,
                          Bets: multipleBets,
                          Wager: props?.wager,
                          CalculateWager: props?.calculatewager,
                          TotalWager: props?.totalwager,
                          Amount: presresp?.maxpayout,
                          OrderId: props?.usergamedetails?._id
                        }
                      }
                    }
    
    
                    let lossamount = 0, gainamount = 0;
                    var resultamount = 0;
                    console.log('props?.gamepercentage', props?.gamepercentage)
                    props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
                        console.log('valllgamepercentage', val, Object.keys(val), Object.values(val)[0],String(presresp?.Multiplier), String(Object.keys(val)) == String(presresp?.Multiplier), String(Object.keys(val)[0]) == String(presresp?.Multiplier));
                        if (String(Object.keys(val)[0]) == String(presresp?.Multiplier)) {
                            let wonpercentage = Number(props?.calculatewager) * Number(Object.values(val)[0]) / 100;
                            let amount = Number(props?.calculatewager) + Number(wonpercentage);
                            console.log('multipliet data',Object.values(val)[0], wonpercentage, amount)
                            if (presresp?.isWin == false) {
                                lossamount = presresp?.maxpayout;
                                resultamount = presresp?.maxpayout;
                            } else {
                                gainamount = amount;
                                resultamount = amount;
                            }
                        } else if ("0x" == String(presresp?.Multiplier)) {
                            if (presresp?.isWin == false) {
                                lossamount = presresp?.maxpayout;
                                resultamount = presresp?.maxpayout;
                            } 
                        }
    
                    });
    
    
                    console.log("lossamount, gainamount", lossamount, gainamount, data)
                    let res = await CreateGameTransactionHooks(data);
                    console.log("orderdata ruru1111", res);
                    if (res?.status == 200) {
                        let createres = await createOrderdetails(props?.pathName, props?.wager, props?.remainingbets, props?.betamount, props.selectedRpc, accountInfo, props.stopgain, props.stoploss, gainamount, lossamount,props?.maxpayout, props?.calculatewager, props?.totalwager, '', '');
                        console.log('createres', createres);
                        if (createres?.status == 200) {
                            let result = createres?.data?.data;
                            if (createres?.data?.status == true) {
                                console.log("update balance", result, result?.RemainingBets == 0)
                                if (result?.RemainingBets == 0) {
                                    let reqData = { _id: result?._id, walletAddress: accountInfo }
                                    let res = await UpdateGameSettingBalancehooks(reqData);
                                    if(res?.data?.status == true) {
                                        setTimeout(() => {
                                            props?.setFeeData()
                                        }, 5000)
                                    }
                                    console.log("update balance 212", res)
                                }
                            }
                            setCheck(true); // useless
                            setTimeout(() => {
                                props?.checkOrder()
                                props.onCheck()
                                setPlay(false)
                            }, 2000)

                            if (createres?.data?.data?.RemainingBets == 0) {
                                let myTimeout = setTimeout(function () {
                                    setRpsResult(0);
                                    setSelectedRpc("Stone");
                                    setRpsbet(false);
                                    setPlay(false);
                                }, 3000);
                                console.log('myTimeoutdata', myTimeout)
                            }
    
    
                        }
                    }
                }
    
                if (presresp?.isWin == false) {
                    let resultamounts =
                    Number(toFixedNumber(Number(resultamount))).toFixed(4) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(4) : Number(toFixedNumber(Number(resultamount))).toFixed(5) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(5) :
                      Number(toFixedNumber(Number(resultamount))).toFixed(6) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(6) : Number(toFixedNumber(Number(resultamount))).toFixed(7) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(7) : Number(toFixedNumber(Number(resultamount))).toFixed(8) > 0 ?
                        Number(toFixedNumber(Number(resultamount))).toFixed(8) : Number(toFixedNumber(Number(resultamount))).toFixed(9) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(9) :
                          Number(toFixedNumber(Number(resultamount))).toFixed(10) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(10) :
                            Number(toFixedNumber(Number(resultamount))).toFixed(11);
                    let myTimeout = setTimeout(function () {
                        toast.error(incrementbetcount + "/" + multipleBets + " " + "  You Lose  " + " " + "-" + resultamounts, {
                            position: position.position,
                            style: style,
                            iconTheme: iconTheme,

                        })
                    }, 1000);
                    console.log('myTimeoutdata', myTimeout)
                } else if (presresp?.isWin == true) {
                    let resultamounts =
                    Number(toFixedNumber(Number(resultamount))).toFixed(4) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(4) : Number(toFixedNumber(Number(resultamount))).toFixed(5) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(5) :
                      Number(toFixedNumber(Number(resultamount))).toFixed(6) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(6) : Number(toFixedNumber(Number(resultamount))).toFixed(7) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(7) : Number(toFixedNumber(Number(resultamount))).toFixed(8) > 0 ?
                        Number(toFixedNumber(Number(resultamount))).toFixed(8) : Number(toFixedNumber(Number(resultamount))).toFixed(9) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(9) :
                          Number(toFixedNumber(Number(resultamount))).toFixed(10) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(10) :
                            Number(toFixedNumber(Number(resultamount))).toFixed(11);
                    let myTimeout = setTimeout(function () {
                        toast.success(incrementbetcount + "/" + multipleBets + " " + "  You Victory  " + " " + "+" + resultamounts, {
                            position: position.position,
                            style: style,
                            iconTheme: iconTheme,
                        })
                    }, 1000)
                    console.log('myTimeoutdata', myTimeout)
                }
                // setRpsResultStatus(false)
                // let sum = incrementbetcount + 1;
                // setIncrementBetCount(sum);
            }

        } catch (e) {
            console.log("FinishInterval_err", e)
        }
    }


    const[timess, setTimess] = useState(true);
      
    useEffect(() => {
       setTimeout(() => {
        
       }, 3000);
     }, [])



    return (
        <div>
            <div className='containersss'>
                <div className='container game_container rps_game'>

        
{console.log("status data", status)}

                    <div className='flex_row_1'>
                        <div className='card card_rps  card_rps selected_card_rps'>
                            {rpsSelected == 1 ?
                                <svg width="50" height="54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.735 37.199c.788-5.548-3.066-10.752-8.614-11.54h6.95a4.647 4.647 0 1 0 0-9.295h-8.333a8.5 8.5 0 0 0-8.5 8.5v9.181a8.501 8.501 0 0 0 4.125 7.289l5.911 3.549v7.138h25.502v-7.364a8.845 8.845 0 0 0 6.851-8.616V10.104" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M40.207 5.683a4.42 4.42 0 0 0-4.42 4.42V16.2a4.42 4.42 0 1 0 8.84 0v-6.095a4.42 4.42 0 0 0-4.42-4.421ZM31.364 2.946a4.42 4.42 0 0 0-4.42 4.42V16.2a4.42 4.42 0 1 0 8.841 0V7.366a4.42 4.42 0 0 0-4.42-4.42ZM18.101 16.199V7.366a4.42 4.42 0 1 0-8.842 0V16.2" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M23.718 20.456a4.423 4.423 0 0 0 3.226-4.257V6.388a4.42 4.42 0 1 0-8.842 0v9.81" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                :
                                rpsSelected == 2 ?
                                    <svg width="45" height="63" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.29 29.906 4.436 6.665a4.219 4.219 0 1 1 8.375-1.027l2.898 23.602M15.855 29.302l4.334-23.817a4.219 4.219 0 1 1 8.3 1.51l-4.495 24.702M38.714 19.383a4.219 4.219 0 0 0-4.219 4.219v5.816a4.219 4.219 0 0 0 8.437 0v-5.816a4.219 4.219 0 0 0-4.218-4.22Z" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M30.277 16.77a4.219 4.219 0 0 0-4.219 4.22v8.428a4.219 4.219 0 0 0 8.437 0V20.99a4.219 4.219 0 0 0-4.218-4.22Z" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M20.133 49.457c.752-5.294-2.926-10.26-8.22-11.012h6.633a4.435 4.435 0 1 0 0-8.87h-7.952a8.112 8.112 0 0 0-8.112 8.113v8.76a8.112 8.112 0 0 0 3.937 6.955l5.64 3.386v4.635h24.336v-4.85a8.44 8.44 0 0 0 6.537-8.222v-24.75" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    :
                                    <svg width="50" height="67" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.4 33.23V12.587a4.386 4.386 0 1 1 8.772 0v20.738M21.184 31.712V6.267a4.386 4.386 0 1 1 8.772 0v25.54M29.958 31.712V11.725a4.386 4.386 0 1 1 8.772 0v20.083" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M38.715 33.325V18.507a4.386 4.386 0 0 1 8.771 0l.083 29.355" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M24.201 50.45c.848-5.965-3.3-11.488-9.265-12.335l-4.203-8.098a4.705 4.705 0 0 0-8.605 3.751l7.747 21.655a7.415 7.415 0 0 0 5.516 4.77v4.891H40.69v-5.042c3.893-.896 6.796-4.382 6.796-8.547V46.94" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>

                            }
                        </div>
                        <div className='rps_bg'>
                            <img src={vsImage} className='img-fluid' />
                        </div>
                        <div className='card card_rps card_rps rpsout'>


                            {status ?
                                <>

                                    <div className='allareas'>
                                        <div class="word"><svg width="50" height="54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.735 37.199c.788-5.548-3.066-10.752-8.614-11.54h6.95a4.647 4.647 0 1 0 0-9.295h-8.333a8.5 8.5 0 0 0-8.5 8.5v9.181a8.501 8.501 0 0 0 4.125 7.289l5.911 3.549v7.138h25.502v-7.364a8.845 8.845 0 0 0 6.851-8.616V10.104" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M40.207 5.683a4.42 4.42 0 0 0-4.42 4.42V16.2a4.42 4.42 0 1 0 8.84 0v-6.095a4.42 4.42 0 0 0-4.42-4.421ZM31.364 2.946a4.42 4.42 0 0 0-4.42 4.42V16.2a4.42 4.42 0 1 0 8.841 0V7.366a4.42 4.42 0 0 0-4.42-4.42ZM18.101 16.199V7.366a4.42 4.42 0 1 0-8.842 0V16.2" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M23.718 20.456a4.423 4.423 0 0 0 3.226-4.257V6.388a4.42 4.42 0 1 0-8.842 0v9.81" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg></div>
                                        <div class="word"><svg width="45" height="63" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.29 29.906 4.436 6.665a4.219 4.219 0 1 1 8.375-1.027l2.898 23.602M15.855 29.302l4.334-23.817a4.219 4.219 0 1 1 8.3 1.51l-4.495 24.702M38.714 19.383a4.219 4.219 0 0 0-4.219 4.219v5.816a4.219 4.219 0 0 0 8.437 0v-5.816a4.219 4.219 0 0 0-4.218-4.22Z" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M30.277 16.77a4.219 4.219 0 0 0-4.219 4.22v8.428a4.219 4.219 0 0 0 8.437 0V20.99a4.219 4.219 0 0 0-4.218-4.22Z" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M20.133 49.457c.752-5.294-2.926-10.26-8.22-11.012h6.633a4.435 4.435 0 1 0 0-8.87h-7.952a8.112 8.112 0 0 0-8.112 8.113v8.76a8.112 8.112 0 0 0 3.937 6.955l5.64 3.386v4.635h24.336v-4.85a8.44 8.44 0 0 0 6.537-8.222v-24.75" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg></div>
                                        <div class="word"><svg width="50" height="67" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.4 33.23V12.587a4.386 4.386 0 1 1 8.772 0v20.738M21.184 31.712V6.267a4.386 4.386 0 1 1 8.772 0v25.54M29.958 31.712V11.725a4.386 4.386 0 1 1 8.772 0v20.083" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M38.715 33.325V18.507a4.386 4.386 0 0 1 8.771 0l.083 29.355" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M24.201 50.45c.848-5.965-3.3-11.488-9.265-12.335l-4.203-8.098a4.705 4.705 0 0 0-8.605 3.751l7.747 21.655a7.415 7.415 0 0 0 5.516 4.77v4.891H40.69v-5.042c3.893-.896 6.796-4.382 6.796-8.547V46.94" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg></div>
                                    </div>
                                </>
                            
                           
                            
                            :rpsResult == 1 ?
                                <div className='rpss'>
                                    {/* {console.log('111 enter thisly', rpsResult)} */}
                                    <svg width="50" height="54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.735 37.199c.788-5.548-3.066-10.752-8.614-11.54h6.95a4.647 4.647 0 1 0 0-9.295h-8.333a8.5 8.5 0 0 0-8.5 8.5v9.181a8.501 8.501 0 0 0 4.125 7.289l5.911 3.549v7.138h25.502v-7.364a8.845 8.845 0 0 0 6.851-8.616V10.104" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M40.207 5.683a4.42 4.42 0 0 0-4.42 4.42V16.2a4.42 4.42 0 1 0 8.84 0v-6.095a4.42 4.42 0 0 0-4.42-4.421ZM31.364 2.946a4.42 4.42 0 0 0-4.42 4.42V16.2a4.42 4.42 0 1 0 8.841 0V7.366a4.42 4.42 0 0 0-4.42-4.42ZM18.101 16.199V7.366a4.42 4.42 0 1 0-8.842 0V16.2" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M23.718 20.456a4.423 4.423 0 0 0 3.226-4.257V6.388a4.42 4.42 0 1 0-8.842 0v9.81" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                </div>
                           
                                :
                                rpsResult == 2 ?
                                <div className='rpss'>
                                {/* {console.log('222 enter thisly', rpsResult)} */}
                                    <svg   width="45" height="63" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.29 29.906 4.436 6.665a4.219 4.219 0 1 1 8.375-1.027l2.898 23.602M15.855 29.302l4.334-23.817a4.219 4.219 0 1 1 8.3 1.51l-4.495 24.702M38.714 19.383a4.219 4.219 0 0 0-4.219 4.219v5.816a4.219 4.219 0 0 0 8.437 0v-5.816a4.219 4.219 0 0 0-4.218-4.22Z" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M30.277 16.77a4.219 4.219 0 0 0-4.219 4.22v8.428a4.219 4.219 0 0 0 8.437 0V20.99a4.219 4.219 0 0 0-4.218-4.22Z" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M20.133 49.457c.752-5.294-2.926-10.26-8.22-11.012h6.633a4.435 4.435 0 1 0 0-8.87h-7.952a8.112 8.112 0 0 0-8.112 8.113v8.76a8.112 8.112 0 0 0 3.937 6.955l5.64 3.386v4.635h24.336v-4.85a8.44 8.44 0 0 0 6.537-8.222v-24.75" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    </div>
                            
                                    :

                                    rpsResult == 3 ?
                                    <div className='rpss'>
                                     {/* {console.log('333 enter thisly', rpsResult)} */}
                                        <svg   width="50" height="67" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.4 33.23V12.587a4.386 4.386 0 1 1 8.772 0v20.738M21.184 31.712V6.267a4.386 4.386 0 1 1 8.772 0v25.54M29.958 31.712V11.725a4.386 4.386 0 1 1 8.772 0v20.083" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M38.715 33.325V18.507a4.386 4.386 0 0 1 8.771 0l.083 29.355" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M24.201 50.45c.848-5.965-3.3-11.488-9.265-12.335l-4.203-8.098a4.705 4.705 0 0 0-8.605 3.751l7.747 21.655a7.415 7.415 0 0 0 5.516 4.77v4.891H40.69v-5.042c3.893-.896 6.796-4.382 6.796-8.547V46.94" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                        </div>
                                    
                                        :
                                        <img src={queImage} className='img-fluid img_ques' />

                            }

                        </div>
                    </div>
{console.log("rpsSelected_rpsSelected", rpsSelected)}
                    <div className='flex_row_2'>
                        <button className={rpsSelected == 1 ? "card card_rps selected_card_rps" : "card card_rps"} disabled={props?.bet} onClick={() => { setRpsSelected(1); setSelectedRpc('Stone'); setRpsbet(true) }}>
                            <div className="checkbox_circle" data-state={rpsSelected == 1 ? "selected" : "nonselected"}>

                            </div>
                            <svg width="50" height="54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.735 37.199c.788-5.548-3.066-10.752-8.614-11.54h6.95a4.647 4.647 0 1 0 0-9.295h-8.333a8.5 8.5 0 0 0-8.5 8.5v9.181a8.501 8.501 0 0 0 4.125 7.289l5.911 3.549v7.138h25.502v-7.364a8.845 8.845 0 0 0 6.851-8.616V10.104" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M40.207 5.683a4.42 4.42 0 0 0-4.42 4.42V16.2a4.42 4.42 0 1 0 8.84 0v-6.095a4.42 4.42 0 0 0-4.42-4.421ZM31.364 2.946a4.42 4.42 0 0 0-4.42 4.42V16.2a4.42 4.42 0 1 0 8.841 0V7.366a4.42 4.42 0 0 0-4.42-4.42ZM18.101 16.199V7.366a4.42 4.42 0 1 0-8.842 0V16.2" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M23.718 20.456a4.423 4.423 0 0 0 3.226-4.257V6.388a4.42 4.42 0 1 0-8.842 0v9.81" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        </button>
                        <button className={rpsSelected == 2 ? "card card_rps selected_card_rps" : "card card_rps"} disabled={props?.bet} onClick={() => { setRpsSelected(2); setSelectedRpc('Scissor'); setRpsbet(true) }}>
                            <div className="checkbox_circle" data-state={rpsSelected == 2 ? "selected" : "nonselected"}>

                            </div>
                            <svg width="45" height="63" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.29 29.906 4.436 6.665a4.219 4.219 0 1 1 8.375-1.027l2.898 23.602M15.855 29.302l4.334-23.817a4.219 4.219 0 1 1 8.3 1.51l-4.495 24.702M38.714 19.383a4.219 4.219 0 0 0-4.219 4.219v5.816a4.219 4.219 0 0 0 8.437 0v-5.816a4.219 4.219 0 0 0-4.218-4.22Z" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M30.277 16.77a4.219 4.219 0 0 0-4.219 4.22v8.428a4.219 4.219 0 0 0 8.437 0V20.99a4.219 4.219 0 0 0-4.218-4.22Z" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M20.133 49.457c.752-5.294-2.926-10.26-8.22-11.012h6.633a4.435 4.435 0 1 0 0-8.87h-7.952a8.112 8.112 0 0 0-8.112 8.113v8.76a8.112 8.112 0 0 0 3.937 6.955l5.64 3.386v4.635h24.336v-4.85a8.44 8.44 0 0 0 6.537-8.222v-24.75" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        </button>
                        <button className={rpsSelected == 3 ? "card card_rps selected_card_rps" : "card card_rps"} disabled={props?.bet} onClick={() => { setRpsSelected(3); setSelectedRpc('Paper'); setRpsbet(true)}}>
                            <div className="checkbox_circle" data-state={rpsSelected == 3 ? "selected" : "nonselected"}>

                            </div>
                            <svg width="50" height="67" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.4 33.23V12.587a4.386 4.386 0 1 1 8.772 0v20.738M21.184 31.712V6.267a4.386 4.386 0 1 1 8.772 0v25.54M29.958 31.712V11.725a4.386 4.386 0 1 1 8.772 0v20.083" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M38.715 33.325V18.507a4.386 4.386 0 0 1 8.771 0l.083 29.355" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M24.201 50.45c.848-5.965-3.3-11.488-9.265-12.335l-4.203-8.098a4.705 4.705 0 0 0-8.605 3.751l7.747 21.655a7.415 7.415 0 0 0 5.516 4.77v4.891H40.69v-5.042c3.893-.896 6.796-4.382 6.796-8.547V46.94" stroke="#fff" stroke-width="3.051" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        </button>
                    </div>
                </div>
            </div>


            <div className='container  dice_btn coin_flex'>
                <button className="btn btn_danger mt-2 mb-3"
                    disabled={btnDisabled}
                    onClick={() => { playrps(roatedcount) }}>
                    Play
                </button>
                <div>
                    {console.log('props.userdtaeil', props?.usergamedetails)}
                    {Object.keys(props?.usergamedetails)?.length > 0 && (props?.usergamedetails?.RemainingBets != props?.usergamedetails?.MultipleBets) && <p>
                        {console.log(rpsWin, "rpswininxide")}
                        {/* You <span>{rpsWin == "Tie" ? "Tie" : rpsWin == "Win" ? "Win" : "Lost"}</span> */}

                    </p>}
                </div>
            </div>
        </div>
    );
}

export default Rps;
