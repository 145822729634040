import Web3 from "web3"
import WalletConnectProvider from "@walletconnect/web3-provider";
import { CHAINS } from "../config/env";
import toast, { Toaster } from 'react-hot-toast';
import {sleep} from './UseContract'
import { EthereumProvider } from "@walletconnect/ethereum-provider";


export const useWeb3 = async() =>{
  
 const RPC_URL      =  CURRENT_RPC_URL();
 const CHAIN_ID     = CURRENT_CHAIN_ID();
 const httpProvider = new Web3.providers.HttpProvider(RPC_URL)

let web3;

  if(localStorage.getItem("walletconnect")!=null){
    const RPC_DATA = {};
    RPC_DATA[CHAIN_ID] = RPC_URL
    const ProjectId = CURR_PROJECTID();
    // const ethereum = new WalletConnectProvider({
    //     rpc: RPC_DATA,
    //     network: 'binance',
    //     chainId: CHAIN_ID,
    //   }
    // );
       //Create WalletConnect Provider
       const provider = await EthereumProvider.init({
        projectId: ProjectId, // required
        chains: [CHAIN_ID], // required
        showQrModal: true // requires @walletconnect/modal
      })
      await provider.enable()
    web3 = new Web3(provider);

  //  return web3;
  }
  else if(typeof window.BinanceChain !== 'undefined'){
 
    web3 = new Web3(window.BinanceChain);
   // return web3;
  }
  
  else if(sessionStorage.getItem("accountInfo")!=null){
   web3 = new Web3(window.ethereum);
 //   return web3;
  }else{
   web3 = new Web3(httpProvider);
 //   return web3;
  }
  const chainIds = await web3.eth.getChainId();
  if(parseInt(chainIds) != parseInt(CHAIN_ID)){
        const data = await SwitchChain();
        if(data){
          window.location.reload();
        }
    }

  return web3;
  
}

export const UseWeb3 = async() =>{
  
  const RPC_URL      =  CURRENT_RPC_URL();
  const CHAIN_ID     = CURRENT_CHAIN_ID();
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
 
 let web3;
 
 if(localStorage.getItem("walletconnect")!=null){
  const RPC_DATA = {};
  RPC_DATA[CHAIN_ID] = RPC_URL
  const ProjectId = CURR_PROJECTID();
  // const ethereum = new WalletConnectProvider({
  //     rpc: RPC_DATA,
  //     network: 'binance',
  //     chainId: CHAIN_ID,
  //   }
  // );
     //Create WalletConnect Provider
     const provider = await EthereumProvider.init({
      projectId: ProjectId, // required
      chains: [CHAIN_ID], // required
      showQrModal: true // requires @walletconnect/modal
    })
    await provider.enable()
  web3 = new Web3(provider);

//  return web3;
}
   else if(typeof window.BinanceChain !== 'undefined'){
  
     web3 = new Web3(window.BinanceChain);
    // return web3;
   }
   
   else if(sessionStorage.getItem("accountInfo")!=null){
    web3 = new Web3(window.ethereum);
  //   return web3;
   }else{
    web3 = new Web3(httpProvider);
  //   return web3;
   }
   const chainIds = await web3.eth.getChainId();
   console.log('chainId_chainId', chainIds, CHAIN_ID)
   if(parseInt(chainIds) != parseInt(CHAIN_ID)){
         const data = await SwitchChain();
         console.log('datasgal_data', data)
         if(!data){
          return false
         } else if(data){
          window.location.reload();
         }
     }
 
   return web3;
   
 }

export const usehttpProvider = async() =>{
  
  const RPC_URL      =  CURRENT_RPC_URL();
  const CHAIN_ID     = CURRENT_CHAIN_ID();
  console.log("RPC_URLRPC_URL",RPC_URL);
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
 
 let web3;
 
   
    web3 = new Web3(httpProvider);
  
   const chainIds = await web3.eth.getChainId();
   console.log("chainIds_chainIds_V",chainIds);
   if(parseInt(chainIds) != parseInt(CHAIN_ID)){
         const data = await SwitchChain();
         if(data){
           window.location.reload();
         }
     }
 
   return web3;
   
 }



export const UseProvider = async () =>{
  const RPC_URL =  CURRENT_RPC_URL();
  const CHAIN_ID = CURRENT_CHAIN_ID();
  var provider = window.BinanceChain;

  if(localStorage.getItem("walletconnect")!=null){
    const RPC_DATA = {};
    RPC_DATA[CHAIN_ID] = RPC_URL
    const ethereum = new WalletConnectProvider({
        rpc: RPC_DATA,
        network: 'binance',
        chainId: CHAIN_ID,
      
    }
    );
 
     await ethereum.enable()
    
 
     return ethereum;
   }
   else if(typeof window.BinanceChain !== 'undefined'){
  
    return window.BinanceChain;
  }
   else if(sessionStorage.getItem("accountInfo")!=null){
     return window.ethereum;
   }
}



export const CURRENT_RPC_URL = ()=>{
   const index = GetChainIndex()
   console.log('====================================');
   console.log('CURRENT_RPC_URL',index);
   console.log('====================================');
   return CHAINS[index].RPC_URL;
}


export const GetChainIndex = ()=>{
 
  return localStorage.getItem("CHAIN") ? localStorage.getItem("CHAIN") : "0";
}

export const CURRENT_CHAIN_ID = ()=>{
  const index = GetChainIndex()
  console.log("index>>>>",index);
  return CHAINS[index].CHAIN_ID;
}


export const CURRENT_BLOCKEXPLORERURL = () => {
  const index = GetChainIndex()
  console.log("index>>>>",index);
  return CHAINS[index].BLOCK_EXPLORER_URL;
}


export const CHAIN_CURRENCY = () => {
  const index = GetChainIndex()
  console.log("index>>>>",index);
  return CHAINS[index].CHAIN_CURRENCY;
}


export const CURR_PROJECTID = () => {
  const index = GetChainIndex()
  console.log("index>>>>",index);
  return CHAINS[index].PROJECT_ID;
}


const SwitchChain = async () => {
  const RPC_URL = CURRENT_RPC_URL();
  const CHAIN_ID = CURRENT_CHAIN_ID();
  const BLOCK_EXPLORER_URL = CURRENT_BLOCKEXPLORERURL();
  const CHAIN_CURRENCY_DATA = CHAIN_CURRENCY();
  console.log("SwitchChain_data", RPC_URL, CHAIN_ID);
  // Check if MetaMask is installed
  // MetaMask injects the global API into window.ethereum
  const hexString = CHAIN_ID.toString(16);
  if (window.ethereum) {
    try {
      // check if the chain to connect to is installed

      const data = window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: "0x" + hexString }], // chainId must be in hexadecimal numbers
      });
      await toast.promise(data, {
        loading: 'Switching Network ...',
        success: 'Network Switched Successfully',
        error: 'Error ! When Switching Network',
      }, {
        style: {
          minWidth: '300px',
          minHeight: '55px'
        }

      },
        await sleep(1000));
      return true;
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      console.log("error.code === 4902", error.code, error.code === 4902)
      if (error.code === 4902) {
        try {
          const data = await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
            chainId: "0x" + hexString,
            rpcUrls: [RPC_URL],
            chainName : "BSC-TESTNET",
            nativeCurrency: {
              "name": "BSC-TESTNET",
              "symbol": CHAIN_CURRENCY_DATA,
              "decimals": 18,
          },
            blockExplorerUrls: [BLOCK_EXPLORER_URL],
          },
            ],
          });

          return SwitchChain()

          // await toast.promise(data, {
          //   loading: 'Switching Network ...',
          //   success: 'Network Switched Successfully',
          //   error: 'Error ! When Switching Network',
          // }, {
          //   style: {
          //     minWidth: '300px',
          //     minHeight: '55px'
          //   }
          // });
          // return true;
        } catch (addError) {
          console.error('adderr', addError);
          toast.error(`SwitchChain Error : ${addError}`,
            {
              style: {
                minWidth: '300px',
                minHeight: '55px'
              }
            });
          return false;
        }
      } else {

        toast.error(`SwitchChain  : ${error}`,
        {
          style: {
            minWidth: '300px',
            minHeight: '55px'
          }
        });
      return false;
      }
      console.error(error);
    }
  } else {
    // if no window.ethereum then MetaMask is not installed

    return false;

  }
}
