import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import meta from '../../assets/metamask-logo.svg';
import walletconnect from '../../assets/walletconnect-logo.svg';
import coinbase from '../../assets/coinbase-logo.svg';
import okx from '../../assets/okx-logo.svg';
import bitkeep from '../../assets/bitkeep-logo.svg';
import foxwallet from '../../assets/fox-wallet.svg';

const Referralmodal = (props) => {
    const [referralmodal, setReferralmodal] = useState(true)

    return (

        <>
            <Modal show={referralmodal}
                className='stakess add-nft-inner'
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header    >
                    <Modal.Title className="fw-700">Create Referral Code </Modal.Title>
                    <button type="button" class="btn-close" aria-label="Close" ></button>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>You can create a referral code to share with your friends. When they register with your code, you will receive a 5% rebate on their transactions.</p>
                        <div className="gameborder modalborder mb-3">
                            <input type="number" class="form-control" id="example" placeholder="Enter a Code" aria-describedby="number" />                       
                        </div>
                        <div>
                        <button type="button" class="btn w-100 btn-success earnbtn walletss mt-3" 
                                    // disabled
                                    >Create Code</button>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

        </>

    );
}

export default Referralmodal;