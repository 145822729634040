import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import meta from '../../assets/metamask-logo.svg';
import walletconnect from '../../assets/walletconnect-logo.svg';
import coinbase from '../../assets/coinbase-logo.svg';
import okx from '../../assets/okx-logo.svg';
import bitkeep from '../../assets/bitkeep-logo.svg';
import foxwallet from '../../assets/fox-wallet.svg';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import diceimg from '../../assets/dicingimg.png';
import dicebet from '../../assets/multibets.png';
import dicelimit from '../../assets/dicelimit.png';
import dicelast from '../../assets/finaldice.png';
import { BACKURL } from "../../config/env";

const Dicemodal = (props) => {
    const [dicemodal, setdicemodal] = useState(true)
    const [steps, setSteps] = useState(0)
    const [gamedetails, setGameDetails] = useState(props?.gamedetails);
    console.log("props_gamedetails", props?.gamedetails, gamedetails)
    return (

        <>
            <Modal show={dicemodal}
                className='stakess add-nft-inner howtoplay'
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header onClick={props.onDismiss}>
                    <Modal.Title className="fw-700">{props?.title}</Modal.Title>
                    <button type="button" class="btn-close" aria-label="Close" ></button>
                </Modal.Header>
                <Modal.Body>
                    <div>
                    {console.log("eeeeeeeeeeeee", gamedetails, gamedetails?.length)}
                        {gamedetails && gamedetails?.length > 0 ?
                            <>{

                                gamedetails?.map((val, i) =>
                                    <>
                                        <div className={steps == i  ? 'step1 ' : "d-none"}>
                                            <img className="img-fluid w-100" src={`${BACKURL}/Allgames/${val?.Image}`} alt="images" />
                                            <p className="pt-3">{val?.Description}</p>
                                            <div className="d-flex justify-content-between align-items-center pt-2">
                                                <div><h5 className="fw-600">{`Steps ${i + 1} /`}<span className="f-14">{gamedetails?.length}</span></h5></div>
                                                <div>
                                                    {console.log('indexdata', i, gamedetails?.length, i == gamedetails?.length-1)}
                                                {/* i == 0 ? setSteps(1) : i ==1 ? setSteps(1) : i == 2 ? setSteps(2) : i == 3 ? setSteps(3) : setSteps(5) */}
                                                {/* i == 0 ? setSteps(2) : i ==1 ? setSteps(3) : i == 2 ? setSteps(4) : i == 3 ? setSteps(4) : setSteps(5) */}
                                                    <button type="button" class="btn btn-light fw-700" disabled={i == 0 ? true : false} onClick={() => {setSteps(i-1)}}><IoIosArrowBack /></button>
                                                    <button type="button" class="btn btn-light fw-700 mx-2" disabled={i == gamedetails?.length-1 ? true : false} onClick={() => {setSteps(i+1)}}><IoIosArrowForward /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </>)

                                    
                            }</>
                            

                            : <></>}

                        <button type="button" class="btn w-100 btn-success earnbtn walletss mt-4 mb-3">PLAY</button>
                        <div >
                            <p className="f-14 mb-2"><span className="fw-600">{props?.content}</span> </p>

                        </div>


                    </div>
                </Modal.Body>

            </Modal>

        </>

    );
}

export default Dicemodal;