import React, { useState, useEffect } from "react";
import {  Modal, Dropdown } from 'react-bootstrap';

import { BiSearch } from 'react-icons/bi';



const Coinmodal = (props) => {

    const [coinmodal, setCoinmodal] = useState(true);
    const [swapData, setSwapData] = useState([])

    console.log("paodpad",props);

    useEffect(() => {
        setSwapData(props.swapData)
    }, [props])

    const handleSelect = async (e) => {
        // let filterdata = await SwapTokenss.filter(el => (el?.token == e?.coinname));
        // console.log("filter data", props.modalName,SwapTokenss, filterdata);

        if (props.modalName == "pay") {
            props.setPay(e)
            // props?.setInTokenAddress(filterdata[0].address)
        } else {
            props.setGet(e)
            // props?.setOutTokenAddress(filterdata[0].address)
        }
    }

    const handleSearch = async (e) => {
        if(e?.target?.value != '') {
            let searchData = await searchedToken(swapData, e?.target?.value);
            console.log("searchdataaaaaaa", searchData)
            setSwapData(searchData)
        } else {
            setSwapData(props.swapData)
        }

    }


    const searchedToken = async (records, searchData) => {

        let filterdata = records?.filter((el) => (el?.coinname.toUpperCase()).includes(searchData.toUpperCase()));
        return filterdata;
    }
    return (

        <>

            <Modal show={coinmodal}
                className='stakess add-nft-inner'
                onHide={props.onDismiss}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header onClick={props.onDismiss}>
                    <Modal.Title className="fw-700"></Modal.Title>
                    <button type="button" class="btn-close" aria-label="Close" ></button>
                </Modal.Header>

                <Modal.Body>
                    <h6 className="fw-600 mb-3">Token Search</h6>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text searchicon" id="basic-addon1"><BiSearch className="search" /></span>
                        </div>
                        <input type="search" class="form-control" placeholder="Search by name, symbol or address" aria-label="Username" aria-describedby="basic-addon1" onChange={ (e) => handleSearch(e) }/>
                    </div>

                    <div className="menuheight">
                        {swapData && swapData?.length > 0 && swapData.map((e, i) =>
                            <Dropdown.Item   
                            disabled={props.modalName == "pay" ? e?.coinname == props?.get?.coinname :  e?.coinname == props?.pay?.coinname} 
                            onClick={()=>{
                                props.onDismiss()
                                handleSelect(e)
                            }} >
                                <div className="d-flex align-items-center">
                                    <img src={e.image} className="ethimg etimg me-3" alt="eth" />
                                    <div>
                                        <p className="mb-0 coinname">{e.coinname}</p>
                                        <p className="mb-0 coinsubtext">{e.cointext}</p>
                                    </div>
                                </div>
                            </Dropdown.Item>
                        )}
                    </div>


                </Modal.Body>
            </Modal>

        </>

    );
}

export default Coinmodal;