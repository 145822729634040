import { GAMEADDRESS_ABI } from "../config/Gameabi";
import { ZEROTH_ADDRESS } from "../config/env";
import { ERC20_ABI } from "../config/erc20";
import { FACTORY_ABI, FACTORY_ADDRESS } from "../config/factoryContract";
import { POOL_ABI } from "../config/poolContract";
import { ROUTER_ABI, ROUTER_ADDRESS } from "../config/routerContract";
import { toFixedNumber } from "./UseContract";
import { iconTheme, position, style } from "./useToast";
import { UseWeb3 } from "./useWeb3";
import BigNumber from 'bignumber.js';
import toast from "react-hot-toast";

const UseROUTER = async () => {
  try {
    let web3 = await UseWeb3();
    let RouterContract = new web3.eth.Contract(ROUTER_ABI, getRouterAddress());
    return RouterContract;
  } catch (e) {

  }

}


const UseTokenInfo = async (TOKEN) => {
  try {
    console.log("UseTokenInfo_UseTokenInfo", TOKEN)
    const token = await UseERC20(TOKEN);
    const name = await token.methods.name().call();
    const symbol = await token.methods.symbol().call();
    const decimals = await token.methods.decimals().call();
    console.log("111 UseTokenInfo_UseTokenInfo", name)
    const data = {
      name: name,
      symbol: symbol,
      decimals: decimals,
      address: TOKEN
    }
    console.log("data_data_Vdatav", data);
    return data;
  } catch (e) {
    console.log('UseTokenInfo', e)
  }
}

const UseFACTORY = async (address) => {
  try {
    let web3 = await UseWeb3();
    let factoryContract = new web3.eth.Contract(FACTORY_ABI, address);
    return factoryContract;
  } catch (e) {

  }

}

const UseERC20 = async (tokenAddress) => {
  try {
    let web3 = await UseWeb3();
    console.log("werbdata", web3.eth)
    let erc20tokenContract = new web3.eth.Contract(ERC20_ABI, tokenAddress);
    console.log("erc20tokenContract", erc20tokenContract)
    return erc20tokenContract;
  } catch (e) {

  }

}



export const approveRouter = async (token, account) => {
  try {
    const erc20contract = await UseERC20(token);
    let approve = await erc20contract.methods.approve(getRouterAddress(), "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account });
    return approve
  } catch (e) {

  }

}


const getRouterAddress = () => {
  try {
    const routerAddress = ROUTER_ADDRESS;
    return routerAddress;
  } catch (e) {

  }
}


export const UsegetAmountsIn = async (_token0, _token1, amount) => {


  try {

    var token0 = _token0;
    var token1 = _token1;
    let getAmountsOut = 0;
    const factoryAddress = FACTORY_ADDRESS;
    let factoryContract = await UseFACTORY(factoryAddress)
    var pair = ZEROTH_ADDRESS;
    var tiers = [0.01 * 10000, 0.05 * 10000, 0.25 * 10000, 1 * 10000]
    for (var i = 0; pair == ZEROTH_ADDRESS; i++) {
      pair = await factoryContract.methods.getPool(token0, token1, tiers[i]).call();
    }
    console.log("pairdata", pair)
    const pool = await USEPOOL(pair);
    console.log("poolllllll", pool)
    var Token0 = await pool.methods.token0().call()
    var Token1 = await pool.methods.token1().call()
    const slot0 = await pool.methods.slot0().call();
    console.log("Token0, Token1", Token0, Token1, token0, Token0 === token0, slot0)
    var token0info = await UseTokenInfo(_token0);
    var token0infodecimal = parseInt(token0info.decimals)
    var token1info = await UseTokenInfo(token1);
    var token1infodecimal = parseInt(token1info.decimals);
    const price = slot0?.sqrtPriceX96 ** 2 / 2 ** 192;
    const equivalentToken = await GetequivalentToken(token0infodecimal, token1infodecimal, price);
    console.log("equivalentToken", equivalentToken);

    if (Token0 === token0) {
      getAmountsOut = amount * equivalentToken;
    } else {
      getAmountsOut = amount / equivalentToken;
    }
    return { getAmountsOut: getAmountsOut }
  } catch (error) {
    console.log(error, "this is the error for getPrice")
  }
}

export const USEPOOL = async (pair) => {
  const web3 = await UseWeb3();
  const poolContract = new web3.eth.Contract(POOL_ABI, pair)
  return poolContract;
}

export const swapTokens = async (token0, token1, amountIn, amountOut, account) => {
  try {
    console.log("token0, token1, amountIn,amountOut,fee, account", token0, token1, amountIn, amountOut, account)
    const web3 = await UseWeb3();

    const AdminAccount = account;
    const tokenInfo0 = await UseTokenInfo(token0);
    const tokenInfo1 = await UseTokenInfo(token1);
    const uniswapRouterAddress = ROUTER_ADDRESS; // Replace with the actual address of Uniswap V3 router contract
    const uniswapRouterContract = await UseROUTER()
    const gasPrice = await web3.eth.getGasPrice();
    console.log("gaspricedata", gasPrice)
    const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
    const amount = toFixedNumber((parseFloat(amountIn) * 10 ** parseInt(tokenInfo0.decimals))).toString();
    let amountOutMin = 0;
    amountOutMin = toFixedNumber((parseFloat(amountOut) * 10 ** parseInt(tokenInfo1.decimals))).toString();
    console.log("amountOutMin_amountOutMin", amountOutMin);
    console.log('amount_parms_data', amountIn, amount);
    var params = {
      tokenIn: token0.toString(),
      tokenOut: token1.toString(),
      fee: "500",
      recipient: AdminAccount,
      amountIn: amount,
      amountOutMinimum: 0,
      sqrtPriceLimitX96: 0
    };
    console.log("params_data", params, await uniswapRouterContract.methods)
    const dataC = await uniswapRouterContract.methods.exactInputSingle(params).encodeABI();
    console.log("datacdata", dataC, AdminAccount, uniswapRouterAddress)
    const estimatedGasLimit = await web3.eth.estimateGas({
      from: AdminAccount,
      to: uniswapRouterAddress,
      data: dataC,
    });
    console.log("estimatedGasLimit", estimatedGasLimit, gasPrice)
    const data = uniswapRouterContract.methods
      .exactInputSingle(params)
      .send({ from: AdminAccount, gas: estimatedGasLimit, gasPrice, value: "0" });
    await toast.promise(data, {
      loading: 'Swapping...',
      success: 'Swapped Successfully',
      error: 'Try Again',
    }, {
      position: position.position,
      style: style,
      iconTheme: iconTheme
    });
    console.log("transdata", data, data?.transactionHash, amount)
    console.log("transdatenterthis")
    return true;


    return { hash: data?.transactionHash, in: amount, token0: tokenInfo0, token1: tokenInfo1 };
  } catch (e) {
    console.log("error SWAP", e);
  }
}

export const GetequivalentToken = async (_tokenIn, _tokenOut, _amount) => {

  return _tokenIn > _tokenOut ? _amount * (10 ** (_tokenIn - _tokenOut)) : _amount / (10 ** (_tokenIn - _tokenOut));

}




export const getUserBalance = async (token, account) => {

  try {
    console.log("tokenAddress, accountInfo", token, account)
    const tokenContract = await UseERC20(token);
    const tokenInfo = await UseTokenInfo(token);
    console.log("getUserBalance_tokenInfo", tokenInfo)
    const balance = await tokenContract.methods.balanceOf(account).call();
    console.log("form,to balancecheck", balance)
    const balanceTokens = ((new BigNumber(balance)).div(new BigNumber(10).pow(tokenInfo.decimals))).toNumber();
    return balanceTokens.toString();
  } catch (e) {

  }
}


export const checkIsApprovedRouter = async (account, token) => {
  console.log("account, token_data", account, token);
  const erc20contract = await UseERC20(token);
  const allow = await erc20contract.methods.allowance(account, getRouterAddress()).call();
  return (parseInt(allow) > 0) ? true : false
}



export const approveSwap = async (account, token) => {
  console.log('accout------', account);
  console.log("token-------", token);
  try {

    const erc20Contract = await UseERC20(token);
    const data = erc20Contract.methods.approve(ROUTER_ADDRESS, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account });
    console.log("approve_dasta", data)
    await toast.promise(data, {
      loading: 'Approving...',
      success: 'Approved Successfully',
      error: 'Try Again',
    }, {
      position: position.position,
      style: style,
      iconTheme: iconTheme
    });
    return data;
  } catch (error) {
    console.log("err--", error);
  }

}