import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Nav, Navbar, NavDropdown, Dropdown, Offcanvas } from 'react-bootstrap';
import Copimg from '../assets/copyimg.svg';
import Logo from '../assets/logo1.png';
import walletimg from '../assets/wallet.png';
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import menuburger from '../assets/menuburger.png';
import { getChainId } from "../hooks/useAccount";
import { CHAINS } from "../config/env";


import Wallets from '../Components/Modals/Wallet';
import { getuserdatabyrefferalIdhooks } from "../hooks/UseUser";

export default function Header() {
    let location = useLocation();
    const [currentChain, setcurrentChain] = useState(0)
    const [walletmodal, setWalletmodal] = useState(false)
    const [show, setShow] = useState(false);
    const [wallet, setShowWallet] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showpage, setShowPage] = useState(true);
    const [accountInfo, setAccountInfo] = useState("");
    console.log("accountt------", accountInfo);
    const [userdata, setUserData] = useState({});
    console.log("userdatasdfasdfasdf", showpage , walletmodal)

    let navigate = useNavigate()
    useEffect(() => {
        async function fetchData() {
            let refferalId = location?.pathname?.split("/")[1];
            console.log("licatiasdfasdf", location?.pathname?.split("/")[1]);
            if (refferalId != null && refferalId != "" && refferalId != undefined ) {
                let res = await getuserdatabyrefferalIdhooks(refferalId);
                console.log("resdata", res?.data?.record);
                if (res?.data?.success == true) {
                    setUserData(res?.data?.record)
                    setShowPage(true)
                } else {
                    setUserData({})
                    setShowPage(false)
                }
            } 
        }


        fetchData()
    }, [location]);


    const chainId = async () => {
        let chaindd = getChainId()
        console.log("chaindd_chainddchaindd", chaindd.at, CHAINS[currentChain]);
        //  let chainid =chaindd.CHAIN_ID
        //   console.log("chainid_chainid_chainidv",chainid);
        setcurrentChain(chaindd)

    }


    useEffect(() => {
        chainId()
        //  const index = localStorage.getItem("CHAIN");
        //  console.log("index___indexindex",index);
        //  setcurrentChain(index)

        const walletAddress = sessionStorage.accountInfo ? setAccountInfo(sessionStorage.getItem("accountInfo")) : ""
    }, [accountInfo]);

    const logOut = async () => {
        console.log("header logout call")
        setAccountInfo("");
      
        localStorage.removeItem('accessToken');
        sessionStorage.removeItem('accountInfo')
        window.location.href = "/"
        window.location.reload()
    }

    return (
     
        <>

           {showpage &&  <div>
                {walletmodal && <Wallets onDismiss={() => setWalletmodal(false)} />}
                {/* <div class="header_alert__8Mnqu">
                    <div class="header_alert__text__uomPs"><div> */}
                        {/* <span class="header_winr__Lq3w1">$WINR</span> */}
                        
                         {/* is now listed on Camelot DEX. Offic
                        ial contract address */}
                        
                        
                        {/* : */}
                        
                        
                        {/* </div> */}
                        
                        
                        {/* <span class="header_address__pkb9E">0xD77B108d4f6cefaa0Cae9506A934e825BEccA46E */}

                            {/* <svg width="17" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.556 1.686H5.444A2.444 2.444 0 0 0 3 4.13v6.112a2.444 2.444 0 0 0 2.444 2.444h3.112A2.444 2.444 0 0 0 11 10.242V4.13a2.444 2.444 0 0 0-2.444-2.444Z" stroke="#68758C" stroke-width="2"></path><path d="M14 4.686v8.556a2.444 2.444 0 0 1-2.444 2.444H6" stroke="#68758C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg> */}
                        {/* </span> */}
                        
                        {/* </div></div> */}


                <div className="header2">
                    <div className="homemenumobile">
                        <div className="d-flex justify-content-between py-3 px-3 align-items-center">
                            <img src={Logo} alt="images" />
                            <img onClick={handleShow} className="mobileburger" src={menuburger} alt="menu" />
                        </div>
                    </div>
                    <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className="fw-700">PIGIBET</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className="text-center navmobile">
                            <div>
                                {/* <Nav.Link href="/">DOCS</Nav.Link> */}
                                {/* <Nav.Link href="/stake">GWLP STAKING</Nav.Link> */}
                                <Link to="/games" className="listout"><button type="button" class="btn btn-warning wallet play mobileplay mt-3 mb-3"> PLAY </button></Link>
                                {(accountInfo == "" || accountInfo == null) ?
                                    <button type="button" class="btn btn-warning wallet" onClick={() => setWalletmodal(true)}><img src={walletimg} alt="images" /> Connect wallet</button> :


                                    <div className="ms-0 walletdrop mb-3">
                                        <Dropdown onMouseLeave={() => setShowDropdown(false)}
                                            onMouseOver={() => setShowDropdown(true)}>
                                            <Dropdown.Toggle className="ptoken btn btn-success earnbtn" id="dropdown-basic">
                                                <img className="wimg" src={walletimg} alt="images" /> 0x0a3C...2af9
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu show={showDropdown}>
                                                <div className="d-flex justify-content-between tokenborder">
                                                    <p className="f-13 mb-0">0x0a3C...2af9</p>
                                                    <svg width="17" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.556 1.686H5.444A2.444 2.444 0 0 0 3 4.13v6.112a2.444 2.444 0 0 0 2.444 2.444h3.112A2.444 2.444 0 0 0 11 10.242V4.13a2.444 2.444 0 0 0-2.444-2.444Z" stroke="#68758C" stroke-width="2"></path><path d="M14 4.686v8.556a2.444 2.444 0 0 1-2.444 2.444H6" stroke="#68758C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                                </div>
                                                <div className="mt-3">
                                                    {/* <Link to="/">My Profile</Link> */}
                                                    <Link className="mt-2 mb-0" onClick={() => { logOut() }}>Disconnect</Link>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>}
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                    <div className="homemenudesktop">

                        <Navbar expand="lg" className="bg-body-tertiary">
                            <Container>
                                <Navbar.Brand href="/"><img src={Logo} alt="images" /></Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav" className="mobilenavs">
                                    <Nav className="me-auto">
                                        {/* <Nav.Link href="#home">DOCS</Nav.Link>
                                        <Nav.Link href="/stake">GWLP STAKING</Nav.Link> */}

                                    </Nav>

                                    {/* {
                                    (accountInfo == "" || accountInfo == null) ?
                                        <button className="nav-link btn_yellow wallet_btn" onClick={() => { setShowWallet(true) }}>Connect Wallet</button> :
                                        <button className="nav-link btn_yellow wallet_btn" onClick={() => { logOut() }}>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</button>
                                } */}
                                    {/* <Link to="/games"><button type="button" class="btn btn-warning wallet play mobileplay mt-3 mb-3"> PLAY </button></Link> */}
                                    {(accountInfo == "" || accountInfo == null) ?
                                        <button type="button" class="btn btn-warning wallet" onClick={() => setWalletmodal(true)}><img src={walletimg} alt="images" /> Connect wallet</button>



                                        :
                                        <div className="ms-0 walletdrop mb-3">
                                            <Dropdown onMouseLeave={() => setShowDropdown(false)}
                                                onMouseOver={() => setShowDropdown(true)}>
                                                <Dropdown.Toggle className="ptoken btn btn-success earnbtn" id="dropdown-basic">

                                                    <img className="wimg" src={walletimg} alt="images" />    {`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu show={showDropdown}>
                                                    <div className="d-flex justify-content-between tokenborder">
                                                        <p className="f-13 mb-0">   {`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</p>
                                                        <svg width="17" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.556 1.686H5.444A2.444 2.444 0 0 0 3 4.13v6.112a2.444 2.444 0 0 0 2.444 2.444h3.112A2.444 2.444 0 0 0 11 10.242V4.13a2.444 2.444 0 0 0-2.444-2.444Z" stroke="#68758C" stroke-width="2"></path><path d="M14 4.686v8.556a2.444 2.444 0 0 1-2.444 2.444H6" stroke="#68758C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                                    </div>
                                                    <div className="mt-3">
                                                        
                                                        <Link className="mt-2 mb-0" onClick={() => logOut()}>Disconnect</Link>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        
                                        
                                        }

                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </div>
                </div>
            </div>}
        </>
    );
}