import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";

import { CURRENT_CHAIN_ID, CURRENT_RPC_URL, CURR_PROJECTID } from "./useWeb3";
import toast, { Toaster } from 'react-hot-toast';
import { UserWalletRegisterHooks } from "./UseUser";

import { EthereumProvider } from '@walletconnect/ethereum-provider'


export const MetamaskWallet = async (e) => {

    try {
        console.log("enterthissssssss")
        if (window.ethereum) {
            const web3 = new Web3(window.ethereum);


            try {


                await window.ethereum.enable().then(async () => {

                    const accounts = await web3.eth.getAccounts();
                    const account = accounts[0].toString();
                    console.log("accountdata", account)
                    await WalletConnectRegistration(account, "Metamask")
                    // sessionStorage.setItem("accountInfo", account)

                    return web3;
                });
            } catch (e) {
                console.log("MetamaskWallet_err", e)
            }
        }
        // Legacy DApp Browsers
        else if (window.web3) {
            const web3 = new Web3(window.web3.currentProvider);
            const chainId = await web3.eth.getChainId();
            const accounts = await web3.eth.getAccounts();
            const account = accounts[0].toString();
            await WalletConnectRegistration(account, "Metamask")
            // sessionStorage.setItem("accountInfo", account)
            return web3;
        }
        // Non-DApp Browsers
        else {
            //alert('No Dapp Supported Wallet Found');

            toast.error(`No Dapp Supported Wallet Found !`,

                {
                    duration: 2000,
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                });
        }
    } catch (e) {
        toast.error(`ErrorGH : ${e}`,
            {
                duration: 2000,
                style: {
                    minWidth: '300px',
                    minHeight: '55px'
                }
            });
    }


}

export const BinanceWallet = async (e) => {


    try {
        if (window.BinanceChain) {
            const web3 = new Web3(window.BinanceChain);


            try {


                await window.BinanceChain.enable().then(async () => {

                    const accounts = await web3.eth.getAccounts();
                    const account = accounts[0].toString();
                    await WalletConnectRegistration(account, "Binancewallet")
                    // sessionStorage.setItem("accountInfo", account)

                    return web3;
                });
            } catch (e) {

                // User has denied account access to DApp...
            }
        }
        // Legacy DApp Browsers
        else if (window.web3) {

            const web3 = new Web3(window.web3.currentProvider);
            const chainId = await web3.eth.getChainId();

            const accounts = await web3.eth.getAccounts();
            const account = accounts[0].toString();
            await WalletConnectRegistration(account, "Binancewallet")
            // sessionStorage.setItem("accountInfo", account)
            return web3;
        }
        // Non-DApp Browsers
        else {
            //alert('No Dapp Supported Wallet Found');

            toast.error(`No Dapp Supported Wallet Found !`,
                {
                    duration: 2000,
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                });
        }
    } catch (e) {
        toast.error(`BinanceWallet_Error : ${e}`,
            {
                duration: 2000,
                style: {
                    minWidth: '300px',
                    minHeight: '55px'
                }
            });
    }


}


export const WalletConnect = async (e) => {
    const RPC_URL = CURRENT_RPC_URL();
    const CHAIN_ID = (CURRENT_CHAIN_ID());
    //Create WalletConnect Provider

    const RPC_DATA = {};
    RPC_DATA[CHAIN_ID] = RPC_URL
    const provider = new WalletConnectProvider({
        rpc: RPC_DATA,
        network: 'binance',
        chainId: CHAIN_ID,
        // infuraId: "69de03b5c7194095980c9233f9cf71df",
    }
   
    );
    console.log("provider_providerprovider",provider);
    await provider.enable().then(function (error, result) {


    })
        .catch(e => {
            //try again
            toast.error(`WalletConnect_Error : ${e}`,
                {
                    duration: 2000,
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                });
        });
    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();

    const account = accounts[0].toString();
    await WalletConnectRegistration(account, "WalletConnect");
    // sessionStorage.setItem("accountInfo", account)
    // localStorage.setItem("provider",JSON.stringify(provider))


    return provider;
}



export const WalletConnectfun = async () => {
    try {
        console.log("enter this walletconnectfun accountdata")
    const RPC_URL = CURRENT_RPC_URL();
    const CHAIN_ID = CURRENT_CHAIN_ID();
    const ProjectId = CURR_PROJECTID();
    //Create WalletConnect Provider
    const provider = await EthereumProvider.init({
        projectId: ProjectId, // required
        chains: [CHAIN_ID], // required
        showQrModal: true // requires @walletconnect/modal
      })
      await provider.connect()
      
      const web3 = new Web3(provider);
      const accounts = await provider.request({
        method: 'eth_requestAccounts',
      });
        const account = accounts[0].toString();
        console.log("walletconnectfun", account);
        sessionStorage.setItem("accountInfo", account);
        sessionStorage.setItem("wallet","connect");
        localStorage.setItem("walletconnect",true)
        await WalletConnectRegistration(account, "WalletConnect");

        console.log("accountdata", account)
        if(account){
           return web3
        }else{
            return false
        }

}
catch (e) {
    toast.error(`Error : ${e}`,
        {
            duration: 2000,
            style: {
                minWidth: '300px',
                minHeight: '55px'
            }
        });
}
}


const WalletConnectRegistration = async (address, type) => {

    try {
        let data = {
            walletAddress: address,
            type: type,
            refferal : localStorage.getItem("refferalId")
        }
        console.log("req data", data)
        let resp = await UserWalletRegisterHooks(data);
        console.log("WalletConnectRegistration", resp?.status, resp?.data);
        if (resp?.status == 200) {
            sessionStorage.setItem("accountInfo", address);
            localStorage.setItem('accessToken', resp?.data?.accessToken)
            localStorage.removeItem("refferalId")
            window.location.href = "/"

        }
    } catch (e) {
        console.log("WalletConnectRegistration_err", e)
    }
}

