import React, { useState, useEffect } from "react";


export default function Gametable(props) {
const [refferalData, setRefferalData] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        setRefferalData(props.refferalDetails)
console.log("propsdatsgal", props.refferalDetails)
    }, [props])

    const [gametable, setgametable] = useState([
        { "id": 1, }, { "id": 2, }, { "id": 3, }, { "id": 4, }, { "id": 5, }, { "id": 6, }, { "id": 7, }, { "id": 8, }, { "id": 9, }, { "id": 10, },
    ]);

    return (
        <>
            <div className="alltable refalltable">          
                <table class="table table-hover table_gme reftable">
                    <thead>
                        <tr>
                            <th scope="col">Player</th>
                            {/* <th scope="col">Edge Volume</th>
                            <th scope="col">Rebate</th>                         */}
                             <th scope="col">CommissionEarned</th>
           
                        </tr>
                    </thead>
                    <tbody>
                        {refferalData && refferalData?.length > 0 ? refferalData?.map((e, i) =>
                            <tr>
                                <td>{e?.userId}</td>
                                <td>{e?.ComissiongiventoParent}</td>
                                {/* <td>$2,344,651</td>
                                <td>$2,766</td>                                */}
                            </tr>
                        ) : <>  <tr>
                        <td colSpan={6} className="text-center no_item_text">No items found</td>
                        </tr>
                        </>}
                    </tbody>
                </table>

            </div>

        </>
    );
}